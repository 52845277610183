import "../vendors/modernizr"
import {
    windowWidth,
    windowHeight,
    scrollbar,
    scrollerInit,
    globalScrollingAnimations,
    updateEffectsController,
    updateEffectsControllerID, addEffectToController, worksEffectsInit

} from './globals';
import {
    starfieldInit

} from './starfield';

import gsap from "gsap";
import { TweenMax, TimelineLite,  Power1, Power4, Linear, Circ, Back, Expo } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import {SplitText} from './../vendors/SplitText3.min';
gsap.registerPlugin(SplitText);
//import {SplitText} from './../vendors/SplitText.min';

// gsap.registerPlugin(SplitText);
import $ from 'jquery';
import smoothScrollbar from 'smooth-scrollbar';
import Swiper from "swiper";
import imagesLoaded from "imagesloaded";
import InfiniteScroll from "infinite-scroll";

gsap.config({
    nullTargetWarn: false,
});
window.isMobileMenuOpened = false;


let checkLoadingPage = setInterval(function(){

		if(isLoadedPage === true){
			clearInterval(checkLoadingPage);

			scrollerInit();
			globalScrollingAnimations();
			scrollingAnimations();
			staticAnimations();
			if($(".global-work-wrapper").length){
				let checkWorksEffectsParsed = setInterval(function(){
						console.log(window.isWorkEffectsReady)
						if(window.isWorkEffectsReady === true){
							clearInterval(checkWorksEffectsParsed);
							worksEffectsInit();
							isWorkEffectsReady = false;
						}
					}, 300
				);
			}
			setTimeout(function () {
				otherAnimations();
				console.log(effectsController.items.length)
			},1000)


		}

	}, 1000
);





export function launchAfterGetAjaxPage(){
	if(Modernizr.device_xs){
		window.scrollTo(0,0);

	}
	if($(".global-work-wrapper").length){
		let checkWorksEffectsParsed = setInterval(function(){
				if(window.isWorkEffectsReady === true){
					globalScrollingAnimations();
					scrollingAnimations();
					otherAnimations();
					clearInterval(checkWorksEffectsParsed);
					staticAnimations();
					worksEffectsInit();
					isWorkEffectsReady = false;
				}
			}, 50

		);
	}
	else{
		globalScrollingAnimations();
		scrollingAnimations();
		staticAnimations();
		otherAnimations();
	}


}







function getTranslate3d (el) {
	let values = el.css('transform')
	if(values !== 'none'){
		let transx = values.slice(7, values.length - 1).split(', ')
		return transx[4];
	}
	else{
		return 0;
	}


}


export function scrollingAnimations() {
	console.log("scrolling animations started");


	$('.testEffect2').each(function (index, el) {
		let $element = $(this);
		//console.log($element)
		let partnerTL;
		let scrollTo, wino;
		let updateValues = function updateValues(){
			scrollTo = window.innerHeight;
			wino = $(".testEffect").height();
		};
		updateValues();
		partnerTL = gsap.timeline({
			paused:true,
			scrollTrigger:{
				start: 0,
				end: () => "+=" + scrollTo ,
				scrub: true,

			}
		});
		partnerTL.fromTo($element, {y: 0}, {y:() =>  scrollTo ,ease:Linear.easeNone});
		addEffectToController({timeLine: partnerTL, updateFunc: updateValues,  id:"testo1"}
		);

	});




	$(".no-device_xs .projects-tizer-box").each(function (index, el) {
		const $element = $(this).find(".scrolled-area-box");
		let itemID = 0;
		$(".projects-tizer-box .item").each(function (index, el) {
			const $thisItem = $(this);
			let $itemWorkName = $thisItem.find(".workname");
			let $itemTopDesc = $thisItem.find(".work-desc-top");
			let $scrolledXBox  = $("#howWork .scrolled-x");
			let SmallPicWidth,
				itemOffsetLeft,
				itemEnd,
				pictureWrapWidth,
				pictureFullWidth,
				thisItemWidth,
				filledTextWidth,
				pictureDifference,
				parallaxStart = 0,
				parallaxPicEnd,
				itemCatWidth,
				currentTranslateX = 0
			;

			//ParallaxImage
			let $itemPicture = $thisItem.find(".item-pic");
			let $itemPictureLink = $thisItem.find(".abs-circle-link");
			let $itemCat = $thisItem.find(".filledText");
			let $pictureImg = $thisItem.find(".item-pic img");


			let updateValuesItem = function updateValues(){
				currentTranslateX = getTranslate3d($scrolledXBox)
				SmallPicWidth  = $('.last-projects-list .item').width();
				itemOffsetLeft  = $thisItem.offset().left - currentTranslateX;
				itemEnd = itemOffsetLeft + SmallPicWidth;
				pictureWrapWidth  = $thisItem.find('.item-pic').width();
				pictureFullWidth  = $pictureImg.outerWidth();
				thisItemWidth = $thisItem.find('.item-holder').width();
				filledTextWidth = thisItemWidth - pictureWrapWidth;
				$itemCat.width(filledTextWidth);
				pictureDifference = pictureFullWidth - pictureWrapWidth;

				if(index === 0){
					pictureDifference = pictureDifference / 1.5;
				}
				if(index > 1){
					parallaxStart = itemOffsetLeft - windowWidth;
				}
				parallaxPicEnd  =  itemOffsetLeft + pictureWrapWidth + pictureDifference;
				if(index === 2){
					pictureDifference = pictureDifference / 1.5;
					parallaxPicEnd  =   $thisItem.width() * 2;
					itemEnd = $thisItem.width()*2;
				}
				if(index === 3){
					pictureDifference = pictureDifference / 1.5;
					parallaxPicEnd  =   $thisItem.width();
					itemEnd = $thisItem.width();
				}

			};
			updateValuesItem()


			const pictureParallaxTL = gsap.timeline({
				scrollTrigger:{
					trigger: $element,
					start: () => "+=" + parallaxStart,
					end: () => "+=" + parallaxPicEnd,
					scrub: true,
					onUpdate: self => {
						let munisWidth = filledTextWidth - -$itemPicture.css('transform').split(',')[4];
						$itemCat.width(munisWidth)
					}
				}
			});

			pictureParallaxTL.fromTo($itemCat, {width: filledTextWidth}, {width:() =>  -pictureDifference ,ease:Linear.easeNone},0);
			pictureParallaxTL.fromTo($itemPicture, {x: 0}, {x:() =>  -pictureDifference ,ease:Linear.easeNone},0);
			pictureParallaxTL.fromTo($itemPictureLink, {x: 0}, {x:() =>  -pictureDifference ,ease:Linear.easeNone},0);
			pictureParallaxTL.fromTo($pictureImg, {x: 0}, {x:() =>  pictureDifference ,ease:Linear.easeNone},0);
			addEffectToController({timeLine:pictureParallaxTL, updateFunc: updateValuesItem, id:"projectItemParallaxTL"+itemID});

			const itemTextTL = gsap.timeline({
				scrollTrigger:{
					trigger: $element,
					start: () => "+=" + parallaxStart,
					end: () => "+=" + itemEnd,
					scrub: true,
				}
			});
			itemTextTL.fromTo($itemWorkName, {x: 0}, {x:() => 150 ,ease:Linear.easeNone},0);
			itemTextTL.fromTo($itemTopDesc, {x: 0}, {x:() => 300 ,ease:Linear.easeNone},0);
			addEffectToController({timeLine:itemTextTL, updateFunc: updateValuesItem, id:"projectItemTextTL"+itemID});



			itemID++;
		});


		let scrolledWidth, wantedToScrollX, wantedToScrollText;
		let updateValuesSection = function updateValues(){
			scrolledWidth = $(".scrolled-screen-cent").outerWidth();
			wantedToScrollX = scrolledWidth - windowWidth;
			wantedToScrollText = $("#howWork .scrolled-text").width() - windowWidth;
		};
		updateValuesSection();

		//section main scroll x
		const projectsTimeline = gsap.timeline({
			scrollTrigger:{
				trigger: $element,
				start: "top top",
				end: () => "+=" + wantedToScrollX,
				scrub: true,
				pin: true,
			}
		});
		projectsTimeline.fromTo('#howWork .sect-top ', {y: 0}, {y:() =>  -wantedToScrollX ,ease:Linear.easeNone},0);
		projectsTimeline.fromTo('#howWork .scrolled-x ', {x: 0}, {x:() =>  -wantedToScrollX ,ease:Linear.easeNone},0);
		projectsTimeline.fromTo('#howWork .scrolled-text ', {x: 0}, {x:() =>  -wantedToScrollText ,ease:Linear.easeNone},0);
		addEffectToController({timeLine:projectsTimeline, updateFunc: updateValuesSection,  id:"projectsSectMain"});

		setTimeout(function () {
			$(".projects-tizer-box .item").each(function (index, el) {
				updateEffectsControllerID("projectItemParallaxTL"+index)
				updateEffectsControllerID("projectItemTextTL"+index)
			})

		},1000)
	});




	if(!$('.index-bottom-section').data("novideo")){
		$(".device_xs .index-bottom-section").each(function (index, el) {
			let $element =  $(this).find(".sectbg .video-holder");
			let minusStart = window.innerHeight;
			let plusEnd = window.innerHeight;

			const projectsTimeline = gsap.timeline({
				scrollTrigger:{
					trigger: $element,
					start: () => "top bottom",
					toggleActions: "play none none reverse",
				}
			});
			projectsTimeline.fromTo($element, 0.1, {autoAlpha:0}, {autoAlpha:1},0);

			addEffectToController({timeLine:projectsTimeline, updateFunc: null, id:"indexBottomVideoTL"});
		});
		$(".jsWithBottomVideo").each(function (index, el) {
			let $element =  $(this);
			let isToggled = false;
			let $video =   $(this).find("video")
			let videoElement = $video[0];
			let videoLoaded = false;
			const projectsTimeline = gsap.timeline({
				scrollTrigger:{
					trigger: $element,
					start: "top bottom",
					scrub: false,
					onToggle: self => {
						if(isToggled == false){
							let currentAttr = $video.data("src")
							$video.find("source").attr("src", currentAttr)
							videoElement.load();
							let CheckVideoDownLoadTimer = setInterval(videoLoadFunc, 300);
							function videoLoadFunc() {
								if(!videoLoaded){
									videoElement.pause();

								}
								console.log(videoElement.readyState)
								if(videoElement.readyState >= 4){
									clearInterval(CheckVideoDownLoadTimer)
									videoLoaded = true;
									videoElement.play()
								}
							}
						}

						isToggled = true;
					}
				}
			});

			addEffectToController({timeLine:projectsTimeline, updateFunc: null, id:"withBottomVideo"});

		});

	}
	//my animations

	if($(".starfield").length){
		starfieldInit();
	}



	$(".jsAnimateScaleXText").each(function(index, el){
		let $thisElement = $(this);
		const thisTL = gsap.timeline({
			scrollTrigger:{
				trigger:$thisElement,
				start: "top bottom",
				toggleActions: "play none none reverse",
			}
		});
		thisTL.from($thisElement, 2, {opacity:0, scaleX:2, ease:Expo.easeInOut},0);
		addEffectToController({timeLine:thisTL, updateFunc: null,  id:"scaleXText"+index});
	});

	if($(".index-top-section").length){
		if(!Modernizr.device_xs){
			const $topVideo = $(".index-top-section .sectbg ");
			const topVideoTL = gsap.timeline({
				scrollTrigger:{
					start: 0,
					end: () => "+=" + window.innerHeight ,
					scrub: true,
				}
			});
			topVideoTL.to($topVideo, 6,{ y: "80vh", ease:Linear.easeNone},0)
			addEffectToController({timeLine:topVideoTL, updateFunc: null,  id:"indexSectBg"});

		}
		const $starfieldBox = $(".starfield-wrapper");
		const starfieldBoxTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.projects-tizer-box-wrap',
				start:() => "-=" + window.innerHeight ,
				end: () => "+=" + window.innerHeight/2 ,
				scrub: true,
			}
		});
		starfieldBoxTL.fromTo($starfieldBox, {autoAlpha:1}, {autoAlpha: 0, ease:Linear.easeNone},0);
		addEffectToController({timeLine:starfieldBoxTL, updateFunc: null,  id:"starFieldIndexHide"});
	}

	if($(".no-device_xs .jsStartWhiteHeader").length){
		let $elementExist = $('.jsStartWhiteHeader')
		let $siteHeader  = $(".site-header");
		let headerHeight = $siteHeader.outerHeight() + (windowHeight * 5 / 100);
		let updateValuesBlackTL = function updateValues(){
			headerHeight = $siteHeader.outerHeight() + (windowHeight * 5 / 100);
			console.log(headerHeight)
		};
		updateValuesBlackTL();
		//reverse header start
		let headBlackTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.jsStartWhiteHeader ',
				start: () => "-=" + 90,
				end: () => "+="+ 90,
				scrub: true,

				onToggle: self => {
					console.log(self.progress)
					if(self.progress > 0){
						$siteHeader.addClass("whited")
					}
					else{
						$siteHeader.removeClass("whited")
					}
					if(self.progress === 1){
						$siteHeader.addClass("readyWhite")
					}
					if(self.progress < 1){
						$siteHeader.removeClass("readyWhite")
					}
				}
			}
		})
		headBlackTL.fromTo('.site-header .logo-white',{height:"100%"}, {height:0, ease:Linear.easeNone},0)
		headBlackTL.fromTo('.site-header nav i', {height:0}, {height:"100%", ease:Linear.easeNone},0)
		headBlackTL.fromTo('.site-header .current-lang i', {height:0}, {height:90, ease:Linear.easeNone},0)
		headBlackTL.fromTo('.site-header .soc-list b', {height:0}, {height:"100%", ease:Linear.easeNone},0)
		addEffectToController({timeLine:headBlackTL, updateFunc: null, id:"headWhite"});



		let headBlackOverTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.jsStartWhiteHeader ',
				start: () => "-=" + headerHeight,
				end: () => "+="+ headerHeight,
				scrub: true,

			}
		})
		headBlackOverTL.fromTo('.site-header .blackOverlay', {height: () => "100%"}, {height: () => 0, ease:Linear.easeNone},0)
		headBlackOverTL.fromTo('.site-header .whiteOverlay', {height:() => 0}, {height:() =>"100%", ease:Linear.easeNone},0)
		addEffectToController({timeLine:headBlackOverTL, updateFunc: updateValuesBlackTL, id:"headOverlayHide"});



		let $elementExistBlack = $('.no-device_xs .jsStartBlackHeader')

		let scrollTriggerHeadBlackRevert = gsap.timeline({
			scrollTrigger:{
				trigger:'.jsStartBlackHeader ',
				start: () => "-=" + 90,
				end: () => "+="+ 90,
				scrub: true,
				// animation: headBlackTLRevert,
				onToggle: self => {
					if(self.progress === 1){
						$siteHeader.removeClass("whited")
					}
					else{
						$siteHeader.addClass("whited")
					}
				},
				onUpdate: self => {
					headBlackTL.progress(1 - self.progress);
					if(self.progress === 1){
						$siteHeader.removeClass("whited")
					}
					else{
						$siteHeader.addClass("whited")
					}
				}
			}
		})
		addEffectToController({timeLine:scrollTriggerHeadBlackRevert, updateFunc: null,  id:"headBlack"});

	}


	if($(".about-top-section").length){
		let $aboutSection = $('.about-top-section')
		let topSectHeight,mainTextWidth;
		let updateValuesBPWord = function updateValues(){
			topSectHeight = $(".textEffectHolder").height()
			mainTextWidth = $(".abs-textbox .maintext").width() +  (windowWidth * 4 / 100);
		};
		updateValuesBPWord();

		const bpWordTimeline = gsap.timeline({
			scrollTrigger:{
				trigger:'.about-top-section .textEffectHolder ',
				start: 0,
				end: () => "+=" + topSectHeight ,
				scrub: true,
			}
		});

		bpWordTimeline.fromTo('.no-device_xs .about-top-section .abs-text-holder', {x: 0, y:0}, {x:() =>  -mainTextWidth , y:() =>  topSectHeight, ease:Linear.easeNone},0);
		bpWordTimeline.fromTo('.no-device_xs .about-top-section .filled-text span', {color: "#D70033",}, {color:"#61031A", ease:Linear.easeNone},0);
		addEffectToController({timeLine:bpWordTimeline, updateFunc: updateValuesBPWord, id:"aboutBpWord"});



		let topSectWordHeight;
		let updatebprojectWordFillTL = function updateValues(){
			topSectWordHeight = $(".abs-textbox").height()
		};
		updatebprojectWordFillTL();
		const bprojectWordFillTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.about-top-section .abs-textbox  ',
				start: -1,
				end: () => "+=" + topSectWordHeight ,
				scrub: true,
				onToggle: self => {
					if(self.isActive === false){
						$(".about-top-section .abs-textbox ").addClass("afterEffect")
					}
					else{
						$(".about-top-section .abs-textbox ").removeClass("afterEffect")
					}
				}
			}
		});
		bprojectWordFillTL.fromTo('.about-top-section .filled-text', {height: 0,}, {height:"100%", ease:Linear.easeNone},0);
		addEffectToController({timeLine:bprojectWordFillTL, updateFunc: updatebprojectWordFillTL,  id:"aboutBpWordFill"});


		const videoSectBgTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.bottom-sections-wrapper',
				start: () => "top bottom",
				toggleActions: "play none none reverse",
				scrub: false,
			}
		});
		videoSectBgTL.fromTo('.no-device_xs .about-space-backgrounds .bottom-background', {autoAlpha:0},{  autoAlpha:1, duration:0.1, ease:Linear.easeNone},0);
		addEffectToController({timeLine:videoSectBgTL, updateFunc: null,  id:"videoSectionsBottom"});




		let neverStopHeight,fifteenDuration;
		let updatefifteenTL = function updateValues(){
			neverStopHeight = $(".neverstop-sect").outerHeight();
			fifteenDuration = (windowHeight) + neverStopHeight;
		};
		updatefifteenTL();

		const fifteenTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.about-fifteen-sect ',
				start: "top",
				end: () => "+="+ fifteenDuration,
				scrub: true,
			}
		});
		fifteenTL.fromTo('.no-device_xs .about-fifteen-holder', { y:0}, { y:() =>  fifteenDuration, ease:Linear.easeNone},0);
		addEffectToController({timeLine:fifteenTL, updateFunc: updatefifteenTL, id:"fifteenHolderTl"});


		const fifteenTL2 = gsap.timeline({
			scrollTrigger:{
				trigger:'.about-fifteen-sect ',
				start:() => "+=" + 0,
				end: () => "+="+ window.innerHeight,
				scrub: true,
			}
		});
		fifteenTL2.fromTo('.whitebg', 1, { yPercent:() => 100}, { yPercent:() =>  0, ease:Linear.easeNone},0);
		fifteenTL2.fromTo('.numbers-fill', 1,  { height:() => 0}, { height:() => "100%", ease:Linear.easeNone},0);
		addEffectToController({timeLine:fifteenTL2, updateFunc: null,  id:"fifteenHolderTl2"});



		let neverStopRowHeight;
		let updateneverStopTL = function updateValues(){
			neverStopRowHeight = $(".neverrow").height() + windowHeight;
		};
		updateneverStopTL();

		if(!Modernizr.device_xs){
			const neverStopTL = gsap.timeline({
				scrollTrigger:{
					trigger:'.neverrow ',
					start:() => "top",
					end: () => "+="+ window.innerHeight / 2,
					scrub: true,
				}
			});
			neverStopTL.fromTo('.about-fifteen-sect .never-title', { opacity:0}, { opacity:1, ease:Linear.easeNone},0);
			neverStopTL.fromTo('.no-device_xs .about-fifteen-sect .neverrow .rightcol', { opacity:0}, { opacity:1, ease:Linear.easeNone},0);
			addEffectToController({timeLine:neverStopTL, updateFunc: updateneverStopTL,  id:"neverStopTl"});
		}
		if(Modernizr.device_xs){
			const neverStopTL = gsap.timeline({
				scrollTrigger:{
					trigger:'.neverrow ',
					start:() => "top bottom",
					scrub: false,
					toggleActions: "play none none reverse",
				}
			});
			neverStopTL.fromTo('.about-fifteen-sect .never-title', { opacity:0}, { opacity:1, duration:1, ease:Linear.easeNone},0);
			addEffectToController({timeLine:neverStopTL, updateFunc: updateneverStopTL,  id:"neverStopTl"});
		}


		const neverStopTL2 = gsap.timeline({
			scrollTrigger:{
				trigger:'.neverrow ',
				start:() => "top",
				end: () => "+="+ window.innerHeight * 2.5,
				scrub: true,
			}
		});
		neverStopTL2.fromTo('.no-device_xs .about-fifteen-sect .neverrow .rightcol p', 1,{ y:0}, { y:() =>  -window.innerHeight / 2, ease:Linear.easeNone},0);
		addEffectToController({timeLine:neverStopTL2, updateFunc: null, id:"neverRowTl"});

		if(Modernizr.device_xs ){
			let $existElement = $('.about-fifteen-sect')
			let $aboutPlanetBg = $(".about-space-backgrounds");
			const scrollTriggerFifteen2 = gsap.timeline({
				scrollTrigger:{
					trigger:'.attention-section ',
					start:() => "top bottom",
					end: false,
					toggleActions: "play none none reverse",
					scrub: false,
					onToggle: self => {

						if(self.progress > 0){
							$aboutPlanetBg.addClass("activeJija")
						}
						else{
							$aboutPlanetBg.removeClass("activeJija")
						}
					},
				}
			});
			addEffectToController({timeLine:scrollTriggerFifteen2, updateFunc: null, id:"aboutFifteen"});
		}




		//reverse header end

		const scrollTriggerCosmos = gsap.timeline({
			scrollTrigger:{
				trigger:'.about-first-sects ',
				start: 0,
				end: () => "bottom",
				scrub: true,
			}
		});
		scrollTriggerCosmos.to('.stars1', {yPercent:-7, rotate:-30, ease:Linear.easeNone},0)
		scrollTriggerCosmos.to('.stars2', { yPercent:-13, rotate:-60, ease:Linear.easeNone},0)
		scrollTriggerCosmos.to('.stars3', {yPercent:-16, rotate:-80, ease:Linear.easeNone},0)
		addEffectToController({timeLine:scrollTriggerCosmos, updateFunc: null,  id:"aboutCosmosTl"});





		const canvas = document.getElementById("hero-lightpass");

		const context = canvas.getContext("2d");
		canvas.width = 1920;
		canvas.height = 1080;
		const frameCount = canvas.dataset.frames;
		const images = window.imagesCanvasArray;
		const airpods = {
			frame: 0
		};


		// images.pish итд


		let wantToEnd = $(".about-top-section").height() + windowHeight ;


		const scrollTriggerAirPods = gsap.timeline({
			scrollTrigger: {
				trigger:'.about-top-section  ',
				start: 0,
				end:  () => "bottom+="+ window.innerHeight,
				scrub: true,
			},
			// use animation onUpdate instead of scrollTrigger's onUpdate
		});
		scrollTriggerAirPods.to(airpods, {frame: frameCount - 1, snap: "frame", ease:Linear.easeNone, onUpdate: render},0)
		addEffectToController({timeLine:scrollTriggerAirPods, updateFunc: null,  id:"workCosmosFrames"});

		render();

		function render() {
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.drawImage(images[airpods.frame], 0, 0);
		}

	}



	if($(".works-main-section").length) {
		let $existElement = $('.works-main-section')

		const scrollTriggerCosmos = gsap.timeline({
			scrollTrigger:{
				trigger:'.work-page-wrapper',
				start: 0,
				end: () => "bottom",
				scrub: true,
			}
		});
		scrollTriggerCosmos.to('.stars1', {yPercent:-7,  ease:Linear.easeNone},0)
		scrollTriggerCosmos.to('.stars2', { yPercent:-13, ease:Linear.easeNone},0)
		scrollTriggerCosmos.to('.stars3', {yPercent:-16,  ease:Linear.easeNone},0)
		scrollTriggerCosmos.to('.stars4', {yPercent:-10,  ease:Linear.easeNone},0)
		scrollTriggerCosmos.to('.stars5', {yPercent:-14,  ease:Linear.easeNone},0)
		addEffectToController({timeLine:scrollTriggerCosmos, updateFunc: null,  id:"workCosmos"});



		const parallaxTitlesTL = gsap.timeline({
			scrollTrigger:{
				start: 0,
				end: () => window.innerHeight*2,
				scrub: true,
			}
		});
		parallaxTitlesTL.fromTo('.no-device_xs .works-fix-box', {y:0, opacity:1}, {y:() => -window.innerHeight/2, opacity:0, ease:Linear.easeNone},0);
		addEffectToController({timeLine:parallaxTitlesTL, updateFunc: null, id:"worksFixBoxTL"});

	}

	if($(".service-first-sects").length) {
		let $existElement = $('.service-first-sects')

		if(!Modernizr.device_xs){
			$(".pagenav-right a").hover(function(){
				$(this).closest("li").removeClass("inactive").siblings().addClass("inactive")
			})

			$(".pagenav-right").on("mouseleave", function(){
				$(".pagenav-right li").removeClass("inactive")
			})

			$(".site-header").addClass("no-white-gradient")


			const topSectBgTL = gsap.timeline({
				scrollTrigger:{
					start: 0,
					end: () => "+=" + window.innerHeight,
					scrub: true,
				}
			});
			topSectBgTL.fromTo('.service-top-section', {y:0, opacity:1}, {y:() => window.innerHeight/1.3, opacity:0, ease:Linear.easeNone},0);
			addEffectToController({timeLine:topSectBgTL, updateFunc: null,  id:"serviceTopSectTl"});





			let partnerWantToScroll, partnerContentHeight, partnerTriggerEnd;
			let updatepartnerTL = function updateValues(){
				partnerWantToScroll = windowHeight / 3;
				partnerContentHeight = $(".partner-section .sect-content").height();
				partnerTriggerEnd = partnerWantToScroll + partnerContentHeight + windowHeight;
			};
			updatepartnerTL();
			const partnerTL = gsap.timeline({
				scrollTrigger:{
					trigger:'.partner-section .sect-content',
					start: "top bottom",
					end: () => "+=" + partnerTriggerEnd,
					scrub: true,
				}
			});

			partnerTL.fromTo('.partner-section .sect-content', {y:0}, {y:() => partnerWantToScroll,  ease:Linear.easeNone},0);
			addEffectToController({timeLine:partnerTL, updateFunc: updatepartnerTL, id:"partnerSectContent"});


			const gradSect1TL = gsap.timeline({
				scrollTrigger:{
					trigger: '.site-crea-sect',
					start: "top top",
					end: () => "+=" + window.innerHeight,
					scrub: true,
				},

			});
			gradSect1TL.fromTo('.site-crea-sect .section-holder', {y:0}, {y:() => window.innerHeight,  ease:Linear.easeNone},0);
			addEffectToController({timeLine:gradSect1TL, updateFunc: null,  id:"siteCreaTl"});




			const gradSect2TL = gsap.timeline({
				scrollTrigger:{
					trigger: '.webdesign-sect',
					start: "top top",
					end: () => "+=" + window.innerHeight,
					scrub: true,
				}
			});
			gradSect2TL.fromTo('.webdesign-sect .section-holder', {y:0}, {y:() => window.innerHeight,  ease:Linear.easeNone},0);
			addEffectToController({timeLine:gradSect2TL, updateFunc: null,  id:"webdizHoldTl"});



			const gradSect3TL = gsap.timeline({
				scrollTrigger:{
					trigger: '.branding-sect',
					start: "top top",
					end: () => "+=" + window.innerHeight,
					scrub: true,
				}
			});
			gradSect3TL.fromTo('.branding-sect .section-holder', {y:0}, {y:() => window.innerHeight,  ease:Linear.easeNone},0);
			addEffectToController({timeLine:gradSect3TL, updateFunc: null,  id:"brandingHoldTl"});

		}

		const canvas = document.getElementById("hero-lightpass");
		const context = canvas.getContext("2d");

		canvas.width = 1920;
		canvas.height = 1024;

		const frameCount = canvas.dataset.frames;
		const images = window.imagesCanvasArray;
		const airpods = {
			frame: 0
		};


		const scrollTriggerAirPods2 = gsap.timeline({
			scrollTrigger: {
				trigger:'.service-first-sects ',
				start: 0,
				end: () => "bottom",
				scrub: true,
			},
			// use animation onUpdate instead of scrollTrigger's onUpdate
		});
		scrollTriggerAirPods2.to(airpods, {frame: frameCount - 1, snap: "frame", ease:Linear.easeNone, onUpdate: render},0)
		addEffectToController({timeLine:scrollTriggerAirPods2, updateFunc: null,  id:"workCosmosFrames2"});

		render();
		function render() {
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.drawImage(images[airpods.frame], 0, 0);
		}




	}

	$(".jsLoadVideoBox").each(function(){
		let isThisVideoLoaded = false;
		let videoID = 1;
		let videoElement = $(this)[0];
		var video = document.createElement('video');
		var sourceMP4 = document.createElement("source");
		let extension = ".mp4";
		window.worksEffectsArray.items.push({
			id:"videoStartStop"+videoID,
			st:{
				trigger:$(this),
				start: "top bottom",
				end: () => "bottom top",
				toggleActions: "play none none reverse",
				scrub: false,
				onToggle: self => {
					if(self.isActive === true){
						if(isThisVideoLoaded == false){
							sourceMP4.src = videoElement.dataset.src + extension;
							sourceMP4.type = "video/mp4";
							video.autoplay = true;
							video.loop = true;
							video.muted = true;
							video.playsInline = true;
							video.append(sourceMP4);
							videoElement.append(video);
							isThisVideoLoaded = true;
						}
						console.log("play")
						video.play();
					}
					else{
						console.log("pause")
						video.pause();
					}
				},
			},
			realTL:{
				tlBody:[]
			},
			updateFunc:null
		});
		videoID++;

	});

	if($(".index-bottom-section").length){
		let $existElement = $('.index-bottom-section');
		const videoTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.index-bottom-section ',
				start: "top bottom -=" - window.innerHeight,
				end: () => "+=" + window.innerHeight,
				scrub: true,
			}
		});
		videoTL.fromTo('.no-device_xs .projects-tizer-holder', {y:0}, {y:() => window.innerHeight/1.4,  ease:Linear.easeNone},0);
		addEffectToController({timeLine:videoTL, updateFunc: null,  id:"projectsTizerHolderTL"});



		var bottomSplitText = new SplitText('.index-bottom-section .sect-subtitle', {
			type: "words,chars",
			linesClass:"line++"
		});

		let bottomSideStartFrom;
		let updateValuesVideoBottom = function updateValues(){
			bottomSideStartFrom = window.innerHeight / 5
			if(Modernizr.device_xs){
				bottomSideStartFrom =  window.innerHeight / 1.5;
			}
		};
		updateValuesVideoBottom();
		const videoBottomTextTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.index-bottom-section',
				toggleActions: "play none none reverse",
				start: "-="+bottomSideStartFrom,
				scrub: false,
			}
		});
		videoBottomTextTL.fromTo(bottomSplitText.chars, 1, {
			opacity: 1,
			rotateY: 120,
		},  {
			opacity: 1,
			rotateY: 0,
			stagger: 0.05,
			ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
		},0);

		addEffectToController({timeLine:videoBottomTextTL, updateFunc: updateValuesVideoBottom,  id:"indBottomSectChars"});




		const planetTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.index-sect2 .inspiration-box   ',
				start: "top bottom",
				end: () => "+=" + 5000,
				scrub: true,
			}
		});
		planetTL.fromTo('.space-backgrounds .asteroid1', {scale:0.4, opacity:0.6, rotate:0, x:0, y:0}, {scale:1, opacity:1, rotate:40, x:-500, y:-100,  ease:Linear.easeNone},0);
		planetTL.fromTo('.space-backgrounds .planet-left', {scale:0.7, opacity:0.2}, {scale:1, opacity:0.6, ease:Linear.easeNone},0);
		planetTL.fromTo('.space-backgrounds .asteroid2', {scale:0.5, x:0, y:0, rotate:0}, {scale:0.8,  x:-200, y:100, rotate:-20, ease:Linear.easeNone},0);
		planetTL.fromTo('.space-backgrounds .asteroid3', {scale:0.5, x:0,  rotate:0}, {scale:1, x:"30vw", rotate:120, ease:Linear.easeNone},0);
		planetTL.fromTo('.space-backgrounds .asteroid4', {scale:0.5, opacity:0.6, x:0,  rotate:0}, {scale:1.5, opacity:1, x:500, y:200, rotate:-70, ease:Linear.easeNone},0);
		addEffectToController({timeLine:planetTL, updateFunc: null,  id:"spaceBackgroundIndex"});

	}


	$(".jsBottomWorldCanvas").each(function(index, elem){
		const canvas = $(this)[0];
		const context = canvas.getContext("2d");
		canvas.width = 700;
		canvas.height = 640;
		const frameCount = elem.dataset.frames;
		let thisCanvas = $(this)[0];
		const framesPath = elem.dataset.path;

		let supportedExtension = ".webp";
		if(!Modernizr.webp){
			supportedExtension = ".jpg";
		}
		for (let i = 0; i <= frameCount; i++) {
			const img = new Image();
			img.src = framesPath + i.toString().padStart(4, '0')+supportedExtension;
			imagesCanvasArray2.push(img); // для того, чтобы передать в другой файл скрипта
		}

		const images = window.imagesCanvasArray2;
		const airpods = {
			frame: 0
		};

		let renderInterval;

		function startCanvasAnimation(){
			renderInterval = setInterval(function(){
				context.clearRect(0, 0, canvas.width, canvas.height);
				context.drawImage(images[airpods.frame], 0, 0);
				airpods.frame++;
				if(airpods.frame > frameCount){
					airpods.frame = 0;
				}
			}, 30);
		}

		function stopCanvasAnimation(){
			clearInterval(renderInterval);
		}

		let $element = $('.last-section-block');
		let thisTL;
		thisTL = gsap.timeline({
			paused:true,
			scrollTrigger:{
				trigger:$element,
				start: "top bottom",
				toggleActions: "play none none reverse",
				scrub: false,
				onToggle: self => {
					if(self.isActive === true){
						startCanvasAnimation()
					}
					else{
						stopCanvasAnimation()
					}
				},
			}
		});
		thisTL.to($element, {opacity:1, ease:Linear.easeNone});
		addEffectToController({timeLine: thisTL, updateFunc: null,  id:"lastSectCanvas"});


	})


	if($(".index-bottom-section.mod-type2").length){
		let $existElement = $('.index-bottom-section.mod-type2');
		const videoTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.index-bottom-section.mod-type2',
				start: "top bottom -=" - window.innerHeight,
				end: () => "+=" + window.innerHeight,
				scrub: true,
			}
		});
		videoTL.fromTo('.no-device_xs .index-bottom-section.mod-type2 .sectbg', {y:"-70vh"}, {y:() => 0,  ease:Linear.easeNone},0);
		addEffectToController({timeLine:videoTL, updateFunc: null,  id:"projectsTizerHolderTL33"});


	}


	if($(".about-space-backgrounds").length){
		let $sectHeightDuration  = 0;
		let updateValues = function updateValues(){
			$sectHeightDuration = $('.attention-section').outerHeight() * 1.5
		};
		updateValues();
		const videoTLaa = gsap.timeline({
			scrollTrigger:{
				trigger:'.attention-section',
				start: "top bottom",
				end: () => "+=" + $sectHeightDuration ,
				scrub: true,
			}
		});
		videoTLaa.fromTo('.no-device_xs .about-space-backgrounds .top-background', {opacity:1}, {opacity: 0,  ease:Linear.easeNone},0);
		addEffectToController({timeLine:videoTLaa, updateFunc: updateValues,  id:"projectsTizerHolderTL44"});


		const videoTL2 = gsap.timeline({
			scrollTrigger:{
				trigger:'.attention-section',
				start:() => "top 10%" ,
				end: () => "+="+ window.innerHeight / 1.4 ,
				scrub: true,
			}
		});
		videoTL2.fromTo('.no-device_xs .about-space-backgrounds .asteroid-video', {opacity:0, y: 200}, {opacity: 1, y:0,  ease:Linear.easeNone},0);
		addEffectToController({timeLine:videoTL2, updateFunc: null,  id:"projectsTizerHolderTL445"});

		let isToggled = false;
		let $video =  $('.about-space-backgrounds .asteroid-video').find("video")
		let videoElement = $video[0];
		let videoLoaded = false;
		const projectsTimeline = gsap.timeline({
			scrollTrigger:{
				trigger:'.bottom-sections-wrapper',
				start: "top bottom +=" + $sectHeightDuration,
				scrub: false,
				toggleActions: "play none none reverse",
				onToggle: self => {

					if(isToggled == false){
						console.log(" toggled")
						let currentAttr = $video.data("src")
						$video.find("source").attr("src", currentAttr)
						videoElement.load();
						let CheckVideoDownLoadTimer = setInterval(videoLoadFunc, 300);
						function videoLoadFunc() {
							if(!videoLoaded){
								videoElement.pause();
							}
							console.log(videoElement.readyState)
							if(videoElement.readyState >= 4){
								clearInterval(CheckVideoDownLoadTimer)
								videoLoaded = true;
								videoElement.play()
							}
						}
						videoElement.defaultPlaybackRate = 1.3;
					}

					isToggled = true;
				}
			}
		});

		addEffectToController({timeLine:projectsTimeline, updateFunc: null, id:"withBottomVideo33"});

	}

	$(" .jsLoadVideoBox2").each(function(){
		var $element = $(this);
		let videoElement = $element.find("video")[0];
		let effectLengthEnd = 0;
		const thisTimeLine = gsap.timeline({
			scrollTrigger:{
				trigger:$element,
				start: () => "top bottom",
				end:() =>"bottom top",
				scrub: false,
				onToggle: self => {
					if(self.isActive === true){
						console.log("play")
						videoElement.play();
						console.log(videoElement.duration)

					}
					else{
						console.log("pause")
						videoElement.pause();
					}
				},
			}
		});
		addEffectToController({timeLine:thisTimeLine, updateFunc: null, id:"videoPlayPause"});

	});
	$(" .jsPlayPauseOnScroll").each(function(){
		var $element = $(this);
		let videoElement = $element.find("video")[0];
		let effectLengthEnd = 0;
		const thisTimeLine = gsap.timeline({
			scrollTrigger:{
				trigger:$element,
				start: () => "top bottom",
				end:() =>"bottom top",
				scrub: false,
				onToggle: self => {
					if(self.isActive === true){
						console.log("play")
						videoElement.play();
						console.log(videoElement.duration)

					}
					else{
						console.log("pause")
						videoElement.pause();
					}
				},
			}
		});
		addEffectToController({timeLine:thisTimeLine, updateFunc: null, id:"videoPlayPause"});

	});








	$(".no-device_xs header:not(.works-header)").each(function(){
		var $element = $(this);
		var $elementOverlay = $element.find('.overlay');
		let effectLengthEnd = 0;
		let updateValues = function updateValues(){
			effectLengthEnd = window.innerHeight / 3;
		};
		updateValues();
		const thisTimeLine = gsap.timeline({
			scrollTrigger:{
				start: 0,
				end:() =>  effectLengthEnd,
				scrub: true,
			}
		});
		thisTimeLine.fromTo($element, 1,{y: 0}, {y:() =>  "-5vh" ,ease:Linear.easeNone},0);
		thisTimeLine.fromTo($elementOverlay, 1,{opacity: 0}, {opacity:1 ,ease:Linear.easeNone},0);
		addEffectToController({timeLine:thisTimeLine, updateFunc: updateValues, id:"headToTopTL"});

		const thisTimeLine2 = gsap.timeline({
			scrollTrigger:{
				start: () => effectLengthEnd,
				end: () =>  "+=" + effectLengthEnd,
				scrub: true,
			}
		});
		thisTimeLine2.fromTo($elementOverlay, 1,{y: 0}, {y:() =>  "5vh", ease:Linear.easeNone},0);
		addEffectToController({timeLine:thisTimeLine2, updateFunc: updateValues, id:"headToTopTL2"});

	});



	$(".service-top-section").each(function(index,el){
		let $existElement = $(this);
		let $servisePlanetBg = $(".service-planet-background");
		const videoSectBgTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.bottom-sections-wrapper',
				start: () => "-=" + window.innerHeight*2,
				toggleActions: "play none none reverse",
				scrub: false,
				onToggle: self => {
					if(self.isActive === true){
						$servisePlanetBg.addClass("activeJija")
					}
					else{
						$servisePlanetBg.removeClass("activeJija")
					}
				},
			}
		});
		addEffectToController({timeLine:videoSectBgTL, updateFunc: null,  id:"servTopBottom"+index});



		if (!Modernizr.device_xs){
			const videoSectBgTL2 = gsap.timeline({
				scrollTrigger:{
					trigger:'.bottom-sections-wrapper',
					start:() =>  "top bottom",
					end: () =>  "bottom bottom",
					scrub: true,
				}
			});
			videoSectBgTL2.fromTo('.no-device_xs .service-planet-background .video-bg', {y:() => 500}, {y:() =>  0, ease:Linear.easeNone},0);
			addEffectToController({timeLine:videoSectBgTL2, updateFunc: null,  id:"servicePlanetVideo"+index});
		}


	});

	$(".complexity-list").each(function(){
		let $element = $(this);
		const thisTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.complexity-list',
				start: "top 90%",
				toggleActions: "play none none reverse",
				scrub: false,
			}
		});
		thisTL.fromTo($element.closest('.sect-center-box').find(".line"), {width:0}, {width:"100%", duration:1}, 0);
		thisTL.staggerFromTo($element.find('li i'),  1,{width:0,}, {width:"100%"}, 0.2, 0.2);
		thisTL.staggerFromTo($element.find('li span'), 1, {opacity:0}, {opacity:1}, 0.2, 0.2);
		addEffectToController({timeLine:thisTL, updateFunc: null, id:"complexListTL"});
	})

	$(".matter-section").each(function(){
		let $element = $(this);

		const staggeredTextMatter = new SplitText(".matter-section .sect-title", {
			type: "chars",
		});
		const matterFlipInChars = staggeredTextMatter.chars;
		const matterTitleTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.matter-section ',
				start: "top bottom",
				toggleActions: "play none none reverse",
				scrub: false,
			}
		});
		matterTitleTL.set(matterFlipInChars, {
			transformOrigin: "center center -40px",
			backfaceVisibility: "hidden"
		});
		matterTitleTL.fromTo(matterFlipInChars, {
				opacity: 1,
				rotateY: 120,
			},
			{
				opacity: 1,
				rotateY: 0,
				duration: 0.7,
				stagger: 0.03,
				ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
			},0);
		addEffectToController({timeLine:matterTitleTL, updateFunc: null,  id:"matterSectTL"});

	})
	$(".matter-list li").each(function(index,el){
		let $thisItem = $(this);
		const matterItemTL = gsap.timeline({
			scrollTrigger:{
				trigger:$thisItem,
				start: "top 80%",
				toggleActions: "play none none reverse",
				scrub: false,
			}
		});
		matterItemTL.fromTo($thisItem.find('.line'), { width:0}, {width:"100%", duration:1,},0);
		matterItemTL.fromTo($thisItem.find('.item-title'),  {autoAlpha:0}, {autoAlpha:1, duration:1,ease: Power1.easeInOut},0);
		matterItemTL.fromTo($thisItem.find('.item-subtitle'), { y:10, opacity:0},{ y:0, opacity:1, duration:0.5},0);
		matterItemTL.fromTo($thisItem.find('.galka .galka-circle'), { scale:0},{scale:1, duration:0.5},0);
		matterItemTL.fromTo($thisItem.find('.galka .left-line'), { width:0},{width:15, duration:0.5},1);
		matterItemTL.fromTo($thisItem.find('.galka .right-line'), { width:0},{width:25, duration:0.5},1.5);
		addEffectToController({timeLine:matterItemTL, updateFunc: null, id:"matterItem"+index});
	})


	function worksListScrollAnimationInit(isUpdate){
		$(".works-list-full").each(function(){
			let $element = $(this);
			let durationTimer = 1000;
			if(isUpdate){
				durationTimer = 0;
			}
			setTimeout(function () {
				$(".works-list-full .work-item").each(function(index,el){
					if(isUpdate){
						if(!$(this).hasClass("appended")){
							return;
						}
					}
					const $thisItem = $(this);
					const $itemCategory = $thisItem.find(".work-category span");
					const $itemHolder = $thisItem.find(".holder");
					const $itemCategoryWrap = $thisItem.find(".work-category");
					const $itemSubtitle= $thisItem.find(".work-subname");
					const $itemImageContainer =  $thisItem.find(".img-holder");
					const $itemImage = $thisItem.find(".img-holder img");
					const $itemTitles = $thisItem.find(".work-titles");
					const $itemName = $thisItem.find(".work-name");
					const $itemCircles = $thisItem.find(".circle-wrap");


					const workItemTitlesTL = gsap.timeline({
						scrollTrigger:{
							trigger: $thisItem,
							toggleActions: "play none none none",
							start: "top bottom",
							scrub: false,
						}
					});
					if(!Modernizr.device_xs){

						const staggeredFlipInText = new SplitText($thisItem.find(".work-name"), {
							type: "words,chars",
							linesClass:"line++"
						});
						const staggeredFlipInChars = staggeredFlipInText.chars;
						workItemTitlesTL.set(staggeredFlipInChars, {
							transformOrigin: "center center -40px",
							backfaceVisibility: "hidden"
						});
						workItemTitlesTL.from(staggeredFlipInChars, 1,{
							opacity: 1,
							rotateY: 120,
							duration: 0.7,
							stagger: 0.03,
							ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
						},0);
						addEffectToController({timeLine:workItemTitlesTL, updateFunc: null, id:"workFullItemTitle"+index});



					}
					const workItemTL = gsap.timeline({
						scrollTrigger:{
							trigger: $thisItem,
							toggleActions: "play none none none",
							start: "top bottom",
							scrub: false,
						}
					});
					workItemTL.to($itemHolder, 0.2, {opacity:1, ease: Power1.easeInOut, delay:0}, 0);
					workItemTL.to($itemImageContainer, 1.5, {
						x: 0,
						ease: Power1.easeOut,
						delay:0,
					});
					workItemTL.to($itemImage, 1.5, {
						x: 0,
						scale: 1,
						delay: -1.5,
						ease: Power1.easeOut
					});
					workItemTL.to($itemName, 0.5, {opacity:1, ease: Power1.easeInOut, delay:0}, 0);
					workItemTL.to($itemCategoryWrap, 0.5, {  opacity:1, ease: Power1.easeInOut, delay:0}, 0);
					workItemTL.from($itemCategory, 0.8, { y:20, opacity:0, ease: Power1.easeInOut, delay:0}, 0);
					workItemTL.to($itemCircles, 1, {scale:1, opacity:1, ease: Power1.easeInOut, delay:0}, 0);
					workItemTL.to($itemSubtitle, 1, {opacity:1, ease: Power1.easeInOut, delay:1}, 0);
					addEffectToController({timeLine:workItemTL, updateFunc: null,  id:"workFullItemTL"+index});



				});

			}, durationTimer);


		})
	}
	worksListScrollAnimationInit()



	$(".last-tizer-screen").each(function(){
		let $element = $(this);
		let wantScroll;
		let wantScroll2;
		let winheight;
		let updateValues = function updateValues(){
			wantScroll = window.innerHeight / 1.5 ;
			wantScroll2 = window.innerHeight / 1.3;
			winheight = window.innerHeight;
		};
		updateValues();
		const thisTL = gsap.timeline({
			scrollTrigger:{
				trigger:'.last-tizer-screen',
				start: () => "top bottom",
				end: () => "+=" + winheight,
				scrub: true,
			}
		});
		thisTL.fromTo('.last-tizer-screen .sectbg', { scale:1.15, y:() =>  -winheight}, {scale:1, y:() =>  0, ease:Linear.easeNone},0);
		thisTL.fromTo('.last-tizer-screen .sect-content', { y:() =>  -wantScroll2}, {y:() =>  0, ease:Linear.easeNone},0);
		thisTL.fromTo('.last-tizer-screen .nextwork-item img', { scale:() =>  1}, {scale:() =>  1.2, ease:Linear.easeNone},0);
		addEffectToController({timeLine:thisTL, updateFunc: updateValues,  id:"lastTizerScreenTL"});
	})


	if($(".works-list-full").length){
		let currentLang = "ru";
		if($(".site-header .lang-item-first a").attr("lang") === "ru-RU"){
			currentLang = "en";
		}
		let workSearchPath = "../work/page/{{#}}/";
		// path: '../work/page/{{#}}/',
		if(currentLang == "en"){
			workSearchPath = "../../work/page/{{#}}/";
		}
		console.log(workSearchPath)
		let infScroll = new InfiniteScroll( '.works-list-full', {
			path: workSearchPath,
			append: '.work-item',
			history: false,
		});

		const workLoaderTL = gsap.timeline({
			scrollTrigger:{
				trigger: '#loader',
				start: () => "top bottom",
				scrub: false,
				onToggle: self => {
					if(self.isActive == true){
						if (!$("#loader").hasClass("active")) {
							$("#loader").addClass("active");
							setTimeout(function() {
								infScroll.loadNextPage();
								//scene.update();
								$("#loader").removeClass("active");
							}, 1000, 9);
						}
					}
					else{

					}

				}
			}
		});
		addEffectToController({timeLine:workLoaderTL, updateFunc: null, id:"workLoadertl"});

		// build scene
		infScroll.on( 'append', function( body, path, items, response  ) {
			console.log(items)
			for (let i = 0; i < items.length; i++) { // выведет 0, затем 1, затем 2
				$(items[i]).addClass("appended")
				console.log(items[i])
			}
			//worksListScrollAnimationInit(true)

		});


	}


}



export function otherAnimations() {
	$(".jsAnimeTextLines").each(function (index, el) {
		let $element = $(this);
		let endEffect;
		let updateValues = function updateValues(){
			endEffect = window.innerHeight / 1.5;
		};
		updateValues();
		const thisTimeLine = gsap.timeline({
			scrollTrigger:{
				trigger: $element,
				toggleActions: "play none none reverse",
				start: "top bottom",
				end: () => "+=" + endEffect,
				scrub: false,
			}
		});
		thisTimeLine.staggerFromTo($element.find('span'), 1 , {autoAlpha:0, y:70},{autoAlpha:1, y:0}, 0.2, 0);
		addEffectToController({timeLine:thisTimeLine, updateFunc: updateValues, id:"animeTextLines"+index});
	});


	$(".no-device_xs .jsAnimeTextLinesDesktop").each(function (index, el) {
		let $element = $(this);
		let endEffect;
		let updateValues = function updateValues(){
			endEffect = window.innerHeight / 1.5;
		};
		updateValues();
		var thisSplitText = new SplitText($element, {type:"lines"});
		const thisTimeLine = gsap.timeline({
			scrollTrigger:{
				trigger: $element,
				toggleActions: "play none none reverse",
				start: "top bottom",
				end: () => "+=" + endEffect,
				scrub: false,
			}
		});
		thisTimeLine.staggerFrom(thisSplitText.lines, 1 , {autoAlpha:0, y:70,  ease: Power1.easeInOut}, 0.2, 0);
		addEffectToController({timeLine:thisTimeLine, updateFunc: updateValues, id:"animeTextLinesDesktop"+index});
	});



	$(".jsAnimeTextLinesNormal").each(function (index, el) {
		let $element = $(this);
		var thisSplitText = new SplitText($element, {type:"lines"});
		let endEffect;
		let updateValues = function updateValues(){
			endEffect = window.innerHeight / 1.5;
		};
		updateValues();
		const thisTimeLine = gsap.timeline({
			scrollTrigger:{
				trigger: $element,
				toggleActions: "play none none reverse",
				start: "top bottom",
				end: () => "+=" + endEffect,
				scrub: false,
			}
		});
		thisTimeLine.staggerFromTo(thisSplitText.lines, 1 ,{autoAlpha:0, y:70}, {autoAlpha:1, y:0,  ease: Power1.easeInOut}, 0.2, 0);
		thisTimeLine.staggerFromTo($element.find('span'), 1 , {autoAlpha:0, y:70}, {autoAlpha:1, y:0}, 0.2, 0);
		addEffectToController({timeLine:thisTimeLine, updateFunc: updateValues, id:"animeTextLinesNormal"+index});
	});





	if($('.jsLoveHover').length){
		var loveBox = $(".jsLoveHover")
		let loveBoxWidth = loveBox.width();
		let loveBoxHeight = loveBox.height();
		var inServiceListMouse = false;
		var loveCircle = $(loveBox.data('mask'));
		let imagesCount = loveCircle.find(".item").length;
		let movingPixels = 0;
		let lastRandomPicture = 1;
		function changeWorkSlidePicture(){
			console.log('changa')
			let randomChangePicture = parseInt(getRandomArbitrary(1,imagesCount));
			// if(lastRandomPicture === randomChangePicture ){
			// 	changeWorkSlidePicture();
			// }
			// else{
			// 	loveCircle.find(".item").eq(randomChangePicture).addClass("visible").siblings().removeClass("visible")
			// 	lastRandomPicture = randomChangePicture;
			// }
		}





		if(Modernizr.touchdevice){
			let changeWorkSlideInterval;
			const loveBoxTL = gsap.timeline({
				scrollTrigger:{
					trigger: '.designlove-box',
					start: () => "top bottom",
					end: () => "bottom top",
					scrub: true,
					onToggle: self => {
						if(self.isActive == true){
							changeWorkSlideInterval = setInterval(function(){
								changeWorkSlidePicture();
							},800);
						}
						else{
							clearInterval(changeWorkSlideInterval);
						}

					}
				}
			});
			addEffectToController({timeLine:loveBoxTL, updateFunc: null, id:"designLoveScript"});

		}
		else{
			loveBox.hover(function () {
				follower.addClass("loveHover")
				loveCircle.addClass("visible-mask");
				if(inServiceListMouse){
					loveCircle.addClass("animated");
					setTimeout(function () {
						loveCircle.removeClass("animated")
					}, 200)
				}
			});

			loveBox.on("mousemove", function (e) {
				//cursor moving
				inServiceListMouse = true;
				movingPixels++;

				if(movingPixels >= 30 ){
					changeWorkSlidePicture();
					movingPixels = 0;
				}
				var mouseHovX = e.pageX ;
				var mouseHovY = e.pageY;
				let cursorInsidePositionX = mouseHovX -  $(this).offset().left;
				let cursorInsidePositionY = mouseHovY - $(this).offset().top;
				let x = -parseInt((loveBoxWidth / 2) - cursorInsidePositionX);
				let y = -parseInt((loveBoxHeight / 2) - cursorInsidePositionY + 200);
				if(cursorInsidePositionY > loveBoxHeight || cursorInsidePositionY < 0){
					follower.removeClass("loveHover")
					loveCircle.removeClass("visible-mask");
				}
				else{
					follower.addClass("loveHover")
					loveCircle.addClass("visible-mask");
				}
				gsap.to(loveCircle, {
					css: {
						x: x ,
						y: y
					},
					duration:0.3,
				});

			});
			loveBox.on("mouseleave", function (e) {
				follower.removeClass("loveHover")
				inServiceListMouse = false;
				loveCircle.removeClass("visible-mask");
			})
		}

	}


}

if(Modernizr.device_xs){
	window.xsHeaderTimeLine = gsap.timeline({paused:true});
	var $naviMobile = $(".device_xs .mobile-header-inside")
	xsHeaderTimeLine.fromTo($naviMobile, 0.5, {autoAlpha: 0 },{autoAlpha: 1 },0);
	xsHeaderTimeLine.staggerFrom(".mobile-header-inside .main-nav li", 0.5, { opacity:0, y:-20, ease: Power1.easeInOut}, 0.08, 0);
	xsHeaderTimeLine.from(".mobile-header-inside .bottom-holder", 0.5, {opacity: 0, y:50 },0.5);
	xsHeaderTimeLine.from(".mobile-header-inside .head-bottom .line", 0.5, {width: 0 },0.5);
	$(".mobile-menu-btn").on("click",function(){
		if(window.isMobileMenuOpened == false){
			$(this).addClass("btn-actived")
			$naviMobile.addClass("inside-header-opened")
			$('html').addClass('modal-open')
			xsHeaderTimeLine.timeScale(1);
			xsHeaderTimeLine.play();
			window.isMobileMenuOpened = true;

		}
		else{
			$(this).removeClass("btn-actived")
			$('html').removeClass('modal-open')
			$naviMobile.removeClass("inside-header-opened")
			xsHeaderTimeLine.timeScale(1);
			xsHeaderTimeLine.reverse();
			window.isMobileMenuOpened = false

		}
	});


	$(".jsScrollSpy a").on("click", function(){
		$(".js_mobileBtn").removeClass("btn-actived")
		xsHeaderTimeLine.timeScale(3);
		xsHeaderTimeLine.reverse();
		xsHeaderTimeLine.eventCallback("onReverseComplete",function(){
			$("header .menu-column").slideUp("fast")
			$("header").removeClass("menu-opened")
		});
		mobileMenuOpened = false
	})


}
export const preloaderTimeLine = new TimelineLite({
	paused:true,
	onComplete: function(){
		$(".page-preloader .loaderNumber1")
		gsap.to('.page-preloader .progress-number',0.5,{y:0},0)
		$('.page-preloader').removeClass("loader-long")
	}
});
preloaderTimeLine.fromTo(
	'.page-preloader',
	2,
	{ yPercent:0,  ease: "expo.inOut", delay:0},
	{ yPercent:-100,  ease: "expo.inOut", delay:0},
	0);

preloaderTimeLine.fromTo(
	'.page-preloader .blackbg',
	2,
	{y:0,  ease: "expo.inOut"},
	{y:"40vh",  ease: "expo.inOut"},
	0);
export function staticAnimations() {
	//console.log("staticAnimationLaunched")
	preloaderTimeLine.play(0)

	$(".index-top-section").each(function(){
		const topVideo = $(this).find("video")[0]
		topVideo.play();

		var thisTimeLine = new TimelineLite();
		var thisSplitText = new SplitText('.index-top-section  .text-modern', {
			type: "words,chars",
			linesClass:"line++"
		});
		console.log(thisSplitText.chars)

		thisTimeLine.set(thisSplitText, {
			transformOrigin: "center center -40px",
			backfaceVisibility: "hidden"
		});
		thisTimeLine.from(thisSplitText.chars, 1,{
			opacity: 1,
			rotateY: 120,
			duration: 1,
			stagger: 0.05,
			ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
		},1);

		thisTimeLine.fromTo('.index-top-section  .sect-content', 2, {y:"70vh"}, {y:0, ease: "expo.inOut"},0)
		thisTimeLine.to('.index-top-section  .top-textbox', 2, {opacity:1, y:0,  ease: "expo.inOut"},0.2);
		thisTimeLine.to('.index-top-section  .abs-circle .circle-text', 1, { opacity:1,   ease: Power1.easeInOut},2);
		thisTimeLine.to('.index-top-section  .abs-circle .circle-text', 3, { rotation:300,   ease: "expo.inOut"},1);
		thisTimeLine.to('.index-top-section  .abs-circle .circle-arrow', 2, {opacity:1, scale:1,  ease:"back.inOut(4)"},2);
		thisTimeLine.to('.top-screen-section  ._g-red-arrow-btn', 0.5, { opacity:1,  ease: Power1.easeInOut}, 0.9);
		thisTimeLine.to('.top-screen-section  .star-image img', 1, { opacity:1, scale:1,  ease: Power1.easeInOut}, 0.8);
		thisTimeLine.to('.top-screen-section  .right-col', 0.7, { opacity:1, x:0,  ease: Power1.easeInOut}, 1.3);
		thisTimeLine.to('.top-screen-section  .mouse-link', 0.5, { opacity:1,  ease: Power1.easeInOut}, 1.3);
	});


	if(!Modernizr.device_xs){
		$(".about-top-section").each(function(){
			const thisTimeLine = new TimelineLite();
			thisTimeLine.to('.about-top-section  .abs-textbox', 2, { y:0,  ease: "expo.inOut", delay:0}, 0);
			thisTimeLine.to('.about-top-section  .crea-text' , 2, { y:0,  ease: "expo.inOut", delay:0}, 0);
			console.log("animeAbout")
		});
	}
	if(Modernizr.device_xs){
		$(".about-top-section").each(function(){
			const thisTimeLine = new TimelineLite();
			thisTimeLine.fromTo('.textEffectHolder', {y:"80vh"},{ y:0, duration:2,  ease: "expo.inOut", delay:0}, 0);
		});
	}



	$(".service-top-section").each(function(){
		const staggeredFlipInText = new SplitText(".service-top-section .bottom-text p", {
			type: "words,chars",
			linesClass:"line++"
		});
		console.log("anime Service")
		const thisTimeLine = new TimelineLite();
		const staggeredFlipInChars = staggeredFlipInText.chars;
		thisTimeLine.set(staggeredFlipInChars, {
			transformOrigin: "center center -40px",
			backfaceVisibility: "hidden"
		});

		thisTimeLine.fromTo('.service-top-section  .textEffectHolder', 2, {y:"90vh"}, {y:0, ease: "expo.inOut"},0)
		thisTimeLine.from(staggeredFlipInChars, 1,{
			opacity: 1,
			rotateY: 120,
			delay:1,
			duration: 0.7,
			stagger: 0.03,
			ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
		},0);


	});






	$(".contact-top-section").each(function(){
		const staggeredFlipInText1 = new SplitText(".contact-top-section  .contact-row .col:first-child .contact-value span", {
			type: "chars",
			linesClass:"line++"
		});
		const staggeredFlipInChars1 = staggeredFlipInText1.chars;

		const staggeredFlipInText2 = new SplitText(".contact-top-section  .contact-row .col:last-child .contact-value span", {
			type: "chars",
			linesClass:"line++"
		});
		const staggeredFlipInChars2 = staggeredFlipInText2.chars;




		const thisTimeLine = new TimelineLite();
		thisTimeLine.set(staggeredFlipInChars1, {
			transformOrigin: "center center -40px",
			backfaceVisibility: "hidden"
		});
		thisTimeLine.set(staggeredFlipInChars2, {
			transformOrigin: "center center -40px",
			backfaceVisibility: "hidden"
		});
		thisTimeLine.from(staggeredFlipInChars1, 1,{
			opacity: 1,
			rotateY: 120,
			duration: 0.7,
			stagger: 0.03,
			ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
		},1.5);
		thisTimeLine.from(staggeredFlipInChars2, 1,{
			opacity: 1,
			rotateY: 120,
			duration: 0.7,
			stagger: 0.03,
			ease: "cubic-bezier(0.425, 0.005, 0.0, 1.0)"
		},2);
		thisTimeLine.to('.contact-top-section  .sect-bg', 2, { y:0,  ease: "expo.inOut", delay:0}, 0);
		thisTimeLine.to('.contact-top-section  .container', 2, { y:0,  ease: "expo.inOut", delay:0}, 0);
		thisTimeLine.to('.contact-top-section  .contact-row .col:first-child .line', 1, { width:"100%", delay:0}, 1.3);
		thisTimeLine.to('.contact-top-section  .contact-row .col:first-child .title', 1, { y:0, delay:0}, 1.5);
		thisTimeLine.to('.contact-top-section  .contact-row .col:first-child .contact-value em', 1, { scale:1, opacity:1}, 1.5);


		thisTimeLine.to('.contact-top-section  .contact-row .col:last-child .line', 1, { width:"100%", delay:0}, 1.8);
		thisTimeLine.to('.contact-top-section  .contact-row .col:last-child .title', 1, { y:0, delay:0}, 2);
		thisTimeLine.to('.contact-top-section  .contact-row .col:last-child .contact-value em', 1, { scale:1, opacity:1}, 2);
		thisTimeLine.to('.contact-top-section  .contact-bottom', 1, { y:0, opacity:1, delay:0}, 2.5);
	});

	$(".works-page-title").each(function(){
		const thisTL = new TimelineLite();
		if(!Modernizr.device_xs){
			thisTL.fromTo('.works-page-title', 2, {y:"70vh"}, {y:0, ease: "expo.inOut"},0)
		}
		if(Modernizr.device_xs){
			thisTL.fromTo('.works-fix-box', 2, {y:"70vh"}, {y:0, ease: "expo.inOut"},0)
		}

	});


	// all-works carousel
	$('.no-device_xs .js_allWorksCarousel').each(function(){
		const carouselWrapper = $(this);
		const carouselDiv = $(this).find(".swiper-container")[0]
		let startFrom = 0;
		if ($(this).find('.scrollbar-bottom').data("start-from")){
			startFrom = $(this).find('.scrollbar-bottom').data("start-from")
		}
		var mySwiper = new Swiper(carouselDiv, {
			slidesPerView: 'auto',
			grabCursor: true,
			freeMode: true,
			speed:300,
			initialSlide: startFrom,
			wrapAround: true,
			//slideToClickedSlide:true,
			navigation: {
				nextEl: carouselWrapper.find(".button-next"),
				prevEl: carouselWrapper.find(".button-prev"),
			},
			scrollbar: {
				el: carouselWrapper.find(".swiper-scrollbar")[0],
			},
		});
		if($(this).data("fakescroll")){
			const fakeScroll = carouselWrapper.find(".fakeCarouselScroll");
			const delegator = carouselWrapper.find(".swiper-container");
			var virtSize = mySwiper.virtualSize;
			//console.log(virtSize)
			$(this).find(".fakeslider").width(virtSize);

			var scrollbarSlider = smoothScrollbar.init(fakeScroll[0], {
				damping: 0.07,
				delegateTo:delegator[0]
			});
			mySwiper.on('transitionEnd', function () {
				scrollbarSlider.scrollTo( -this.translate,0, 0, false);
				//console.log(mySwiper.progress)
				if(mySwiper.progress > 0.8){
					carouselWrapper.find('.swiper-button-next').addClass("swiper-button-disabled")
				}
				else{
					carouselWrapper.find('.swiper-button-next').removeClass("swiper-button-disabled")
				}
			});
			scrollbarSlider.addListener(function (s) {
				mySwiper.translateTo(-s.offset.x, 0,true, true );
			});
		}

		var $workCarouselBtn = $(".js_showWorkCarousel");
		$workCarouselBtn.click(function(){
			$(this).closest(".works-bottom-carousel").toggleClass("works-bottom-active")
		})

		var $worksShowMenuBtn = $(".btn-works-show-menu");
		$worksShowMenuBtn.click(function(){
			$(".works-header").toggleClass("active-works-header");
			$(this).closest(".bp-work-header").toggleClass('opened');
			$(".all-works-modal").toggleClass('opened-modal');
		})

		var $worksModalDismiss = $(".all-works-modal .close-area");
		$worksModalDismiss.on("click", function(){
			$(".works-header").removeClass("active-works-header");
			$(".bp-work-header").removeClass('opened');
			$(".all-works-modal").removeClass('opened-modal');
		});


	});



	if(Modernizr.device_xs){
		document.querySelectorAll(".js_xsNoChangeVHNorm").forEach(element => {
			element.style.height = window.innerHeight+10 +"px";
		});
	}


	//service list hovers
	$(".no-touchdevice .jsServiceHover").each(function(){

		var tizerBox = $(this)
		let tizerBoxWidth = tizerBox.width();
		let tizerBoxHeight = tizerBox.height();
		var inTizerListMouse = false;
		var tizerCircle = $(tizerBox.data('mask'));
		let movingTizerPixels = 0;

		if(!Modernizr.device_xs && !Modernizr.dev)
			tizerBox.hover(function () {
				follower.addClass("loveHover")
				tizerCircle.addClass("visible-mask");
				tizerCircle.removeClass("restored");
			});


		tizerBox.on("mousemove", function (e) {
			//cursor moving
			inTizerListMouse = true;
			movingTizerPixels++;
			// $(".workRoundCursor .number").text(movingPixels)
			if(movingTizerPixels >= 20 ){
				movingTizerPixels = 0;
			}
			var mouseHovX = e.pageX ;
			var mouseHovY = e.pageY;
			console.log($(this).offset().left)
			let cursorInsidePositionX = mouseHovX -  $(this).offset().left;
			let cursorInsidePositionY = mouseHovY - $(this).offset().top;
			let x = -parseInt((tizerBoxWidth / 2) - cursorInsidePositionX + 70);
			let y = -parseInt((tizerBoxHeight / 2) - cursorInsidePositionY);
			if(cursorInsidePositionY > tizerBoxHeight || cursorInsidePositionY < 0){
				tizerCircle.removeClass("visible-mask");

			}
			else{
				tizerCircle.addClass("visible-mask");
			}
			gsap.to(tizerCircle, {
				css: {
					x: x ,
					y: y
				},
				duration:1
			});


			//pictures sliding


		});

		tizerBox.on("mouseleave", function (e) {
			follower.removeClass("loveHover")
			inTizerListMouse = false;
			tizerCircle.removeClass("visible-mask");
			tizerCircle.addClass("restored");

		})
	})


	$(".jsBottomWorldCanvas").each(function(){
		let imagesCanvasArrayAfter = [];
		let imagesArrayToLoad = [];
		let thisCanvas = $(this)[0];

		const canvas = $(this)[0];
		const context = canvas.getContext("2d");
		canvas.width = 1000;
		canvas.height = 1000;
		const frameCount = canvas.dataset.frames;
		const images = imagesCanvasArrayAfter;
		const airpods = {
			frame: 1
		};

		setTimeout(function(){
			console.log("canvas video detected");
			const frameCount = thisCanvas.dataset.frames;
			const framesPath = thisCanvas.dataset.path;
			let supportedExtension = ".webp";
			if(!Modernizr.webp){
				supportedExtension = ".jpg";
			}
			for (let i = 0; i <= frameCount; i++) {
				const img = new Image();
				img.src = framesPath + i.toString().padStart(4, '0')+supportedExtension;
				imagesCanvasArrayAfter.push(img); // для того, чтобы передать в другой файл скрипта
				imagesArrayToLoad.push(img)
			}

			var imgLoad = imagesLoaded(imagesArrayToLoad);
			imgLoad.on( 'progress', function(instanse, image) {
				let result = image.isLoaded ? 'loaded' : 'broken';
				//console.log( 'image is ' + result + ' for ' + image.img.src );
			});
			imgLoad.on( 'done', function() {
				console.log("done Loading bottom canvas")
			});
		},2000)

		let renderInterval;

		function startCanvasAnimation(){
			console.log("canvasStart")
			renderInterval = setInterval(function(){
				context.clearRect(0, 0, canvas.width, canvas.height);
				context.drawImage(images[airpods.frame], 0, 0);
				airpods.frame++;
				if(airpods.frame > frameCount){
					airpods.frame = 1;
				}
			}, 30);
		}

		function stopCanvasAnimation(){
			console.log("canvasStop")
			clearInterval(renderInterval);
		}

		let $element = $('.bottom-sections-wrapper');
		let thisTL;
		thisTL = gsap.timeline({
			paused:true,
			scrollTrigger:{
				trigger:$element,
				start: "top bottom",
				toggleActions: "play none none reverse",
				scrub: false,
				onToggle: self => {
					if(self.isActive === true){
						startCanvasAnimation()
					}
					else{
						stopCanvasAnimation()
					}
				},
			}
		});
		thisTL.to($element, {opacity:1, ease:Linear.easeNone});
		addEffectToController({timeLine: thisTL, updateFunc: null,  id:"lastSectCanvas"});


	})

}



function getRandomArbitrary(min, max, excerpt) {
	return Math.random() * (max - min) + min;
}

//fake cursor
if (!Modernizr.device_xs) {
	var follower = $(".fake-cursor");
	var posX = 0,
		posY = 0;
	var mouseX = 0,
		mouseY = 0;
	gsap.to({}, 0.01, {
		repeat: -1,
		onRepeat: function () {
			posX += (mouseX - posX) / 9;
			posY += (mouseY - posY) / 9;
			gsap.to(follower, {
				css: {
					x: posX - 12,
					y: posY - 12
				},
				duration:0.1
			});
		}
	});

	$(document).on("mousemove", function (e) {
		mouseX = e.pageX;
		mouseY = e.pageY;
	});
	$(".no-device_xs").on("mouseenter", '.jsSlideCursor', function () {follower.addClass("can-drag");});
	$(".no-device_xs").on("mouseleave", '.jsSlideCursor', function () {follower.removeClass("can-drag");});

	$(".no-device_xs").on("mouseenter", '.jsButton:not(".noCursor"), a:not(".noCursor")', function () {follower.addClass("active-link");});
	$(".no-device_xs").on("mouseleave", '.jsButton, a', function () {follower.removeClass("active-link");});

	// $(".no-device_xs").on("mouseenter", '.jsCursorDiscover', function () {follower.addClass("active-discover");});
	// $(".no-device_xs").on("mouseleave", '.jsCursorDiscover', function () {follower.removeClass("active-discover");});



}










