import $ from 'jquery'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin);
import {scrollbar} from "./globals";
$("body").on('click', '.js_scrollToLink', function (e) {
    let speed = 1500;
    if($(this).data("speed")){
        speed = $(this).data("speed");
    }

    if(!Modernizr.device_xs && $("#scrolled").length){
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            if ($('#scrolled').length) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                var scrolledAmount = parseInt(target.offset().top);
                var scrolledDiff = scrolledAmount + parseInt(scrollbar.offset.y);
                if($(this).hasClass("scroll-bottom-link")){
                  scrollbar.scrollTo(0, window.innerHeight, speed, false);
                }
                else{
                  if (target.length) {
                    scrollbar.scrollTo(0, scrolledDiff, speed, false);
                  }
                }


            }

            return false;
        }
    }
    else{
        e.preventDefault();
        if($(this).hasClass("scroll-bottom-link")){
          gsap.to(window, {duration: speed / 1000, scrollTo: window.innerHeight});
        }
        else{
          gsap.to(window, {duration: speed / 1000, scrollTo: this.hash});
        }
    }


});
