import { TimelineMax, TweenLite , TweenMax, Linear } from 'gsap';
import $ from 'jquery';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export let windowWidth = window.innerWidth;
export let windowHeight = window.innerHeight;
window.effectsController = {items:[]};
window.isWorkEffectsReady = false;
import smoothScrollbar from "smooth-scrollbar";
window.worksEffectsArray = {items:[]};
var isResized = false;
$(window).on('resize', function(){
    windowHeight = window.innerHeight;
    windowWidth = window.innerWidth;
    //console.log("resized winHeight:" +windowHeight)
    // if(!Modernizr.device_xs){
    //   isResized = true;
    //   setTimeout(function () {
    //     allScrollingEffectsInit();
    //
    //   },500)
    // }

});


export function afterAllPageImagesLoaded(){
    if(!Modernizr.device_xs) {
        // afterAllImagesLoadedEffectsInit();
    }
}
let scroller;
export let scrollbar;
export function scrollerInit(){
    scroller = document.getElementById('scrolled');

    if (!Modernizr.device_xs && scroller) {
        smoothScrollbar.destroy(document.getElementById('horiz-scrollbar'));
        var overscrollOptions = {
            enable: true,
            effect: navigator.userAgent.match(/Android/) ? 'glow' : 'bounce',
            damping: 0.2,
            maxOverscroll: 150,
            glowColor: '#222a2d'
        };
        scrollbar = smoothScrollbar.init(scroller, {
            damping: 0.07
        });

        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    scrollbar.scrollTop = value; // setter
                }
                return scrollbar.scrollTop;    // getter
            },
            getBoundingClientRect() {
                return {top: 0, left: 0, width: windowWidth, height: windowHeight};
            }
        });
        scrollbar.addListener(ScrollTrigger.update);
        ScrollTrigger.defaults({ scroller: scroller });

    }

}

export function globalScrollingAnimations(){


    //global animations
    $("*[data-skarallax]").each(function (index, el) {
        const $element = $(this);
        let wantedToScroll = parseInt($element.data("skarallax"));
        let effectLength;
        let thisUpdate = function updateValues(){
            effectLength = wantedToScroll + $element.outerHeight() + windowHeight;
        };
        thisUpdate();
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger: $element,
                start: "top bottom",
                end: () => "+=" + effectLength,
                scrub: true,
            }
        });
        thisTL.fromTo($element, {y: 0}, {y:() =>  wantedToScroll ,ease:Linear.easeNone},0);
        addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-skarallax"+index});
    });

  //global animations
  $("*[data-skarallax-x]").each(function (index, el) {
    const $element = $(this);
    let wantedToScroll = parseInt($element.data("skarallax-x"));
    let effectLength;
    let additionLength = 0;
    if($element.data("skarallax")){
		additionLength = parseInt($element.data("skarallax"));
	}
    let thisUpdate = function updateValues(){
      effectLength = $element.outerHeight() + windowHeight + additionLength;
    };
    thisUpdate();
    const thisTL = gsap.timeline({
      scrollTrigger:{
        trigger: $element,
        start: "top bottom",
        end: () => "+=" + effectLength,
        scrub: true,
      }
    });
    thisTL.fromTo($element, {x: 0}, {x:() =>  wantedToScroll ,ease:Linear.easeNone},0);
    addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-skarallax-x"+index});
  });


    //global animations
    $("*[data-skarallax-percent]").each(function (index, el) {
        const $element = $(this);
        let to = parseInt($element.data("skarallax-percent"));

        let from = 0;
        if($element.data("from")){
            from = parseInt($element.data("from"));
        }
        let effectLength;
        let blockHeight ;
        let pixelsPercentFrom = 0;
        let pixelsPercentTo = 0;
        let thisUpdate = function updateValues(){

            blockHeight = $element.height()
            pixelsPercentFrom = blockHeight * from / 100;
            pixelsPercentTo = blockHeight * to / 100;
            effectLength = -pixelsPercentFrom + pixelsPercentTo + $element.outerHeight() + windowHeight;
        };
        thisUpdate();
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger: $element,
                start:() => "top bottom -="+pixelsPercentFrom,
                end: () => "+=" + effectLength,
                scrub: true,
            }
        });
        thisTL.fromTo($element, {yPercent:() => from}, {yPercent:() =>  to, ease:Linear.easeNone},0);
        addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-skarallax-percent"+index});
    });


    //global animations
    $("*[data-skarallax-parallax]").each(function (index, el) {
        const $element = $(this);
        let from = 0;
        if($element.data("from")){
            from = parseInt($element.data("from"));
        }
        let effectLength;
        let blockHeight ;
        let blockImageHeight;
        let wantedToScroll;
        let wantedFrom;
        let thisUpdate = function updateValues(){
            blockHeight = $element.height()
            blockImageHeight = $element.find('.image').outerHeight()
            wantedToScroll =  blockImageHeight - blockHeight;
            wantedFrom = window.innerHeight / 2;
            effectLength = wantedToScroll + $element.outerHeight() + windowHeight;
        };
        thisUpdate();
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger: $element,
                start:() => "top bottom",
                end: () => "+=" + effectLength,
                scrub: true,
            }
        });
        thisTL.fromTo($element.find('.image'), {y:() => -wantedFrom}, {y:() =>  wantedFrom, ease:Linear.easeNone},0);
        addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-skarallax-parallax"+index});
    });

  $("*[data-skarallax-parallax-top]").each(function (index, el) {
    const $element = $(this);
    let from = 0;
    let scaleFrom = 1;
    let scaleTo = 1;
    if($element.data("from")){
      from = parseInt($element.data("from"));
    }
    if($element.data("scale-from")){
      scaleFrom = parseFloat($element.data("scale-from"));
    }
    if($element.data("scale-to")){
      scaleTo = parseFloat($element.data("scale-to"));
    }
    let blockImageHeight;
    let wantedFrom;
    let thisUpdate = function updateValues(){
      blockImageHeight = $element.find('.image').outerHeight()
      wantedFrom = window.innerHeight / 2;
    };
    thisUpdate();
    const thisTL = gsap.timeline({
      scrollTrigger:{
        trigger: $element,
        start:() => "top bottom",
        end: () => "+=" + blockImageHeight,
        scrub: true,
      }
    });
    thisTL.fromTo($element.find('.image'), {y:() => -wantedFrom, scale:scaleFrom}, {y:() =>  0, scale:scaleTo, ease:Linear.easeNone},0);
    addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-skarallax-parallax"+index});
  });

    $("*[data-skarallax-scale]").each(function (index, el) {
        const $element = $(this);
        let wantedToScroll = 0;
        let additionDuration = 0;
        const scaleFrom =  parseFloat($element.data("scale-from"));
        const scaleTo =  parseFloat($element.data("skarallax-scale"));
        if($element.data("skarallax-y")){
            wantedToScroll += parseInt($element.data("skarallax-y"))
        }
        if($element.data("diff")){
            additionDuration += parseInt($element.data("diff"))
        }
        let effectLength;
        let thisUpdate = function updateValues(){
            effectLength = wantedToScroll + $element.outerHeight() + windowHeight + additionDuration;
        };
        thisUpdate();
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger: $element,
                start: "top bottom",
                end: () => "+=" + effectLength,
                scrub: true,
            }
        });
        thisTL.fromTo($element, {y: 0, scale:scaleFrom}, {y:() =>  wantedToScroll,scale:scaleTo, ease:Linear.easeNone},0);
        addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-skarallax-scale"+index});
    });


    $("*[data-fixed-end]").each(function(index,el){
        var $element = $(this);
        var diff = 0;
        if($element.data("diff")){
            diff = parseInt($element.data("diff"))
        }
        let wantedToScroll,effectLength;
        let thisUpdate = function updateValues(){
            effectLength = windowHeight;
            wantedToScroll = windowHeight + diff;
        };
        thisUpdate();
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger: $element,
                start: "bottom bottom",
                end: () => "+=" + effectLength,
                scrub: true,
            }
        });
        thisTL.fromTo($element, {y: 0}, {y:() =>  wantedToScroll ,ease:Linear.easeNone},0);
        addEffectToController({timeLine:thisTL, updateFunc: thisUpdate, id:"data-fixed-end"+index});
    });

    $(".jsTitleAnime").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {y: 50, opacity:0}, {y:() =>  0, opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnime"+index});
    });

    $(".jsTitleAnime2").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {y: 50, opacity:0}, {y:() =>  0, opacity:1, duration:2},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnime"+index});
    });


    $(".jsTitleAnimeLeft").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {x: -50, opacity:0}, {x:() =>  0, opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeLeft"+index});
    });

    $(".jsTitleAnimeLeft2").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {x: -100, opacity:0}, {x:() =>  0, opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeLeft2"+index});
    });

    $(".jsTitleAnimeTop").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {y: -100, opacity:0}, {y:() =>  0, opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeTop"+index});
    });

    $(".jsTitleAnimeFade").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, { opacity:0}, { opacity:1, duration:0.7},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeFade"+index});
    });

    $(".jsTitleAnimeFade2").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top center",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, { opacity:0}, { opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeFade2"+index});
    });

    $(".jsTitleAnimeRight").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {x: 50, opacity:0}, {x:() =>  0, opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeRight"+index});
    });

    $(".jsTitleAnimeScale").each(function(index,el){
        var $element = $(this);
        const thisTL = gsap.timeline({
            scrollTrigger:{
                trigger:$element,
                start: "top bottom",
                scrub: false,
                toggleActions: "play none none reverse",
            }
        });
        thisTL.fromTo($element, {scale: 2, opacity:0}, {scale:() => 1, opacity:1, duration:1},0);
        addEffectToController({timeLine:thisTL, updateFunc: null, id:"jsTitleAnimeScale"+index});
    });

    $(".jsAnimeWidth").each(function (index, el) {
        let $element = $(this);
        const thisTimeLine = gsap.timeline({
            scrollTrigger:{
                trigger: $element,
                start: "top bottom",
                toggleActions: "play none none reverse",
            }
        });
        thisTimeLine.fromTo($element, {width:0},{width:"100%", duration:1},0)
        addEffectToController({timeLine:thisTimeLine, updateFunc: null, id:"jsAnimeWidth"+index});
    });







}



export function scrollAfterLoadPage(anchor){
    console.log(anchor)
    if(!Modernizr.device_xs && $("#scrolled").length){
        var target = $(anchor);
        console.log(target)
        var scrolledAmount = parseInt(target.offset().top);
        if (target.length) {
            scrollbar.scrollTo(0, scrolledAmount, 1, false);
        }
    }
    else{
        gsap.to(window, {duration: 1000, scrollTo: anchor});
    }
}



export function updateEffectsController(){
    for (let item of effectsController.items) {
        if(item.updateFunc){
            item.updateFunc();
        }
        item.timeLine.invalidate();
    }
}
export function updateEffectsControllerID(itemID){
    for (let item of effectsController.items) {
        if(item.id === itemID){
            if(item.updateFunc){
                item.updateFunc();
            }
            //console.log("Обновляем скролл контроллер итема: "+ itemID)
            item.timeLine.invalidate();
        }
    }
}
export function addEffectToController(itemToAdd){


    effectsController.items.push({
        timeLine:itemToAdd.timeLine,
        updateFunc: itemToAdd.updateFunc,
        element:itemToAdd.element,
        id:itemToAdd.id,
    });


}



export function worksEffectsInit(){


    console.log("workseffectsInit")
	console.log(worksEffectsArray.items)
    for (let item of worksEffectsArray.items) {
        let thisTL;
        thisTL = gsap.timeline({scrollTrigger:item.st});
        for (let timeLineEffect of item.realTL.tlBody) {
            if(timeLineEffect[0] === "fromTo"){
                thisTL.fromTo(timeLineEffect[1], timeLineEffect[2], timeLineEffect[3], timeLineEffect[4], timeLineEffect[5]);
            }
            if(timeLineEffect[0] === "from"){
                thisTL.from(timeLineEffect[1], timeLineEffect[2], timeLineEffect[3], timeLineEffect[4]);
            }
          if(timeLineEffect[0] === "to"){
            thisTL.to(timeLineEffect[1], timeLineEffect[2], timeLineEffect[3], timeLineEffect[4]);
          }
            if(timeLineEffect[0] === "staggerFrom"){
                thisTL.staggerFrom(timeLineEffect[1], timeLineEffect[2], timeLineEffect[3], timeLineEffect[4], timeLineEffect[5]);
            }
        }

        addEffectToController({timeLine: thisTL, updateFunc:item.updateFunc,  id:item.id});
    }

}


window.addEventListener("resize", function() {
    updateEffectsController();
});
















(function init100vh(){
    function setHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setHeight();
    window.addEventListener('resize', setHeight);
})();





