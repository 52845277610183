import {
    windowWidth,
    windowHeight,
    scrollbar

} from './globals';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from 'jquery';


export function starfieldInit(){
    var mobile = false;

    //---

    if ( Modernizr.device_xs  && Modernizr.device_sm) {

        mobile = true;

    }

    //---

    var canvasWidth = windowWidth;
    var canvasHeight = windowHeight;

    //---

    var canvas = document.createElement( 'canvas' );
    var boda = document.getElementById( 'scrolled' );
    canvas.setAttribute( 'width', canvasWidth );
    canvas.setAttribute( 'height', canvasHeight );
    canvas.oncontextmenu = function( e ) {
        e.preventDefault();
    };

    if ( !mobile ) {

        canvas.addEventListener( 'mousemove', mouseMoveHandler );
        boda.addEventListener( 'mouseenter', mouseEnterHandler );
        canvas.addEventListener( 'mouseleave', mouseLeaveHandler );


    } else {

        canvas.addEventListener( 'touchstart', touchStartHandler, false );
        canvas.addEventListener( 'touchend', touchEndHandler, false );
        canvas.addEventListener( 'touchmove', touchMoveHandler, false );
        canvas.addEventListener( 'touchcancel', touchCancelHandler, false );

    }

    document.getElementById( 'effect' ).appendChild( canvas );

    //---

    var ctx = canvas.getContext( '2d' );
    var imageData = ctx.getImageData( 0, 0, canvasWidth, canvasHeight );
    var pix = imageData.data;

    //---

    var MATHPI180 = Math.PI / 180;
    var MATHPI2 = Math.PI * 2;

    var center = { x:canvas.width / 2, y:canvas.height / 2 };

    //---

    var mouseActive = false;
    var mouseDown = false;
    var mousePos = { x:center.x, y:center.y };

    //---

    var rotationSpeed = -1.00;
    var rotationSpeedFactor = { x: 0, y: 0 };
    rotationSpeedFactor.x = rotationSpeed / center.x;
    rotationSpeedFactor.y = rotationSpeed / center.y;

    var fov = 600; //кучность в центре
    var fovMin = 210;
    var fovMax = fov;

    var starHolderCount = 3000; // кол во звезд кучность

    var starHolder = [];
    var starBgHolder = [];
    var velocity = 0;
    var starSpeed = 0;
    var starSpeedMin = -10;
    var starSpeedMax = 20;
    var starDistance = 8000;
    var starRotation = 0;
    var scrollTriggerStars;
    var scrollingAmountUp = 0;
    var backgroundColor = { r:0, g:0, b:0, a:0 };
    if(Modernizr.device_xs){
        starHolderCount = 2000;
        fov = 500;
    }
    var colorInvertValue = 0;

    //---

    function clearImageData() {

        for ( var i = 0, l = pix.length; i < l; i += 4 ) {

            pix[ i ] = backgroundColor.r;
            pix[ i + 1 ] = backgroundColor.g;
            pix[ i + 2 ] = backgroundColor.b;
            pix[ i + 3 ] = backgroundColor.a;

        }

    };

    function setPixel( x, y, r, g, b, a ) {

        var i = ( x + y * canvasWidth ) * 4;

        pix[ i ] = r;
        pix[ i + 1 ] = g;
        pix[ i + 2 ] = b;
        pix[ i + 3 ] = a;

    };

    function setPixelAdditive( x, y, r, g, b, a ) {

        var i = ( x + y * canvasWidth ) * 4;

        pix[ i ]     = pix[ i ] + r;
        pix[ i + 1 ] = pix[ i + 1 ] + g;
        pix[ i + 2 ] = pix[ i + 2 ] + b;
        pix[ i + 3 ] = a;

    };

    //---

    function drawLine( x1, y1, x2, y2, r, g, b, a ) {

        var dx = Math.abs( x2 - x1 );
        var dy = Math.abs( y2 - y1 );

        var sx = ( x1 < x2 ) ? 1 : -1;
        var sy = ( y1 < y2 ) ? 1 : -1;

        var err = dx - dy;

        var lx = x1;
        var ly = y1;

        while ( true ) {

            if ( lx > 0 && lx < canvasWidth && ly > 0 && ly < canvasHeight ) {

                setPixel( lx, ly, r, g, b, a );

            }

            if ( ( lx === x2 ) && ( ly === y2 ) )
                break;

            var e2 = 2 * err;

            if ( e2 > -dx ) {

                err -= dy;
                lx += sx;

            }

            if ( e2 < dy ) {

                err += dx;
                ly += sy;

            }

        }

    };

    //---

    function addParticle( x, y, z, ox, oy, oz ) {

        var particle = {};
        particle.x = x;
        particle.y = y;
        particle.z = z;
        particle.ox = ox;
        particle.oy = oy;
        particle.x2d = 0;
        particle.y2d = 0;

        return particle;

    };



    function runScrollTrigger(){
        let $thisElement = $(".starfield");
        let scrollingProgress = 0;
        let triggerOffsetTop = $(".projects-tizer-box-wrap").offset().top
        let pinStarfield = true;
        let refreshStarsAmountScrollUp = 300;
        if(Modernizr.device_xs){
            refreshStarsAmountScrollUp = 150;
            pinStarfield = false;

        }
        scrollTriggerStars = ScrollTrigger.create({
            trigger: $thisElement,
            start: "top top",
            end: () => "+=" + triggerOffsetTop,
            pin: pinStarfield,
            scrub: true,
            onToggle: self => {
                if(self.isActive === false){
                    starSpeed = 0;
                }
            },
            onUpdate: self => {
                /// console.log(self)
                velocity = self.getVelocity() / 100;
                starSpeed = velocity;

                if(self.progress < 0.01){
                    starSpeed = 0;
                }
                if(self.direction === -1){
                    scrollingAmountUp += 1;
                    if(scrollingAmountUp > refreshStarsAmountScrollUp){
                        scrollingAmountUp = 0;
                        addParticles();
                    }
                }


            },

        });





    }

    function addParticles() {

        var i;

        var x, y, z;

        var colorValue;
        var particle;

        for ( i = 0; i < starHolderCount / 3; i++ ) {

            x = Math.random() * 24000 - 12000;
            y = Math.random() * 4500 - 2250;
            z = Math.round( Math.random() * starDistance );//Math.random() * 700 - 350;

            colorValue = Math.floor( Math.random() * 55 ) + 5;

            particle = addParticle( x, y, z, x, y, z );
            particle.color = { r:colorValue, g:colorValue, b:colorValue, a:255 };

            starBgHolder.push( particle );

        }

        for ( i = 0; i < starHolderCount; i++ ) {

            x = Math.random() * 10000 - 5000;
            y = Math.random() * 10000 - 5000;
            z = Math.round( Math.random() * starDistance );//Math.random() * 700 - 350;

            colorValue = Math.floor( Math.random() * 155 ) + 100;

            particle = addParticle( x, y, z, x, y, z );
            particle.color = { r:colorValue, g:colorValue, b:colorValue, a:255 };
            particle.oColor = { r:colorValue, g:colorValue, b:colorValue, a:255 };
            particle.w = 1;
            particle.distance = starDistance - z;
            particle.distanceTotal = Math.round( starDistance + fov - particle.w );

            starHolder.push( particle );

        }

    };

    //---

    window.requestAnimFrame = ( function() {

        return  window.requestAnimationFrame       ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame    ||
            function( callback ) {
                window.setTimeout( callback, 1000 / 60 );
            };

    } )();

    function animloop() {

        requestAnimFrame( animloop );
        render();

    };

    function render() {

        clearImageData();

        //---

        var i, j, l, k, m, n;

        //---

        var rx, rz;

        var star;
        var scale;



        if ( starSpeed > starSpeedMax )
            starSpeed = starSpeedMax;


        //не обязательно(удалить потом)
        // fov -= 1;
        // if ( fov < fovMin ) fov = fovMin;


        if(starSpeed <= 0.7 &&  starSpeed >= 0){
            starSpeed = 0;
        }
        if(starSpeed >= -0.7 &&  starSpeed <= 0){
            starSpeed = 0;
        }
        //---

        var warpSpeedValue;

        if ( mobile ) {

            warpSpeedValue = starSpeed * ( starSpeed / starSpeedMax );

        } else {

            warpSpeedValue = starSpeed * ( starSpeed / ( starSpeedMax / 2 ) );

        }


        for ( i = 0, l = starHolder.length; i < l; i++ ) {

            star = starHolder[ i ];

            star.z -= starSpeed;
            star.distance += starSpeed;


            if ( star.z < -fov + star.w ) {

                star.z = starDistance;
                star.distance = 0;

            }


            //---
            //star color

            var distancePercent = star.distance / star.distanceTotal;

            star.color.r = Math.floor( star.oColor.r * distancePercent );
            star.color.g = Math.floor( star.oColor.g * distancePercent );
            star.color.b = Math.floor( star.oColor.b * distancePercent );

            //---
            //star draw

            scale = fov / ( fov + star.z );

            star.x2d = ( star.x * scale ) + center.x;
            star.y2d = ( star.y * scale ) + center.y;

            if ( star.x2d > 0 && star.x2d < canvasWidth && star.y2d > 0 && star.y2d < canvasHeight ) {

                setPixelAdditive( star.x2d | 0, star.y2d | 0, star.color.r, star.color.g, star.color.b, 255 );

            }


            // ЗАКОМЕНТИТЬ ЕСЛИ НЕ НУЖЕН ВАРП ->>>
            if ( starSpeed != starSpeedMin ) {

                var nz = star.z + warpSpeedValue;



                scale = fov / ( fov + nz );

                var x2d = ( star.x * scale ) + center.x;
                var y2d = ( star.y * scale ) + center.y;

                if ( x2d > 0 && x2d < canvasWidth && y2d > 0 && y2d < canvasHeight ) {

                    drawLine( star.x2d | 0, star.y2d | 0, x2d | 0, y2d | 0, star.color.r, star.color.g, star.color.b, 255 );

                }

            }
            // <<<-- ЗАКОМЕНТИТЬ ЕСЛИ НЕ НУЖЕН ВАРП

        }

        //---

        ctx.putImageData( imageData, 0, 0 );

        //---

        if ( mouseActive ) {
            // поворот перспективы в зависимости от положения мыши по оси х
            // center.y += ( mousePos.y - center.y ) * 0.010;
            // center.x += ( mousePos.x - center.x ) * 0.010;

        } else {

            center.x += ( ( canvas.width / 2 ) - center.x ) * 0.015;

        }

    };

    //---

    function mouseMoveHandler( event ) {

        mousePos = getMousePos( canvas, event );

    };

    function mouseEnterHandler( event ) {

        //mouseActive = true;
        starRotation -= 0.5;
    };

    function mouseLeaveHandler( event ) {

        mouseActive = false;

        mouseDown = false;

    };

    //---

    function getMousePos( canvas, event ) {

        var rect = canvas.getBoundingClientRect();

        return { x: event.clientX - rect.left, y: event.clientY - rect.top };

    };

    //---

    function touchStartHandler( event ) {

        event.preventDefault();

        mouseDown = true;
        mouseActive = true;

    };

    function touchEndHandler( event ) {

        event.preventDefault();

        mouseDown = false;
        mouseActive = false;

    };

    function touchMoveHandler( event ) {

        event.preventDefault();

        mousePos = getTouchPos( canvas, event );

    };

    function touchCancelHandler( event ) {

        mouseDown = false;
        mouseActive = false;

    };

    //---

    function getTouchPos( canvas, event ) {

        var rect = canvas.getBoundingClientRect();

        return { x: event.touches[ 0 ].clientX - rect.left, y: event.touches[ 0 ].clientY - rect.top };

    };

    //---

    addParticles();
    runScrollTrigger();
    animloop();


}


