import $ from 'jquery';
import gsap from "gsap";
import {CustomEase} from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);
CustomEase.create('linear', 'M0,0 C0.25,0.5 0,1 1,1')


$(document).on('mousemove', '.glue', function (e) {
    const $glue = $(this);
    $glue.addClass("hovered")
    const mouseX = Math.min(Math.max(e.pageX - $glue.offset().left, 0), $glue.outerWidth());
    const mouseY = Math.min(Math.max(e.pageY - $glue.offset().top, 0), $glue.outerHeight());
    const percX = (mouseX - $glue.outerWidth() / 2) / ($glue.outerWidth() / 2);
    const percY = (mouseY - $glue.outerHeight() / 2) / ($glue.outerHeight() / 2);
    const tl = gsap.timeline();
    const $glueCircle = $('.glue__circle', this);
    if ($glueCircle.length === 0) {
        return
    }
    const glueCircleX = $glue.width() / 2 * percX;
    const glueCircleY = $glue.height() / 2 * percY;
    tl.to($glueCircle, {x: glueCircleX, y: glueCircleY, force3D: !1, duration: 0.5, ease: 'linear'}, 0);
    const $glueCircleInner = $('.glue__circle-inner', this);
    if ($glueCircleInner.length === 0) {
        return
    }
    const glueCircleInnerX = ($glueCircle.width() - $glueCircleInner.outerWidth()) / 2 * percX;
    const glueCircleInnerY = ($glueCircle.height() - $glueCircleInner.outerHeight()) / 2 * percY;
    tl.to($glueCircleInner, {
        x: glueCircleInnerX,
        y: glueCircleInnerY,
        duration: 0.5,
        ease: 'linear'
    }, 0);
});
$(document).on('mouseleave', '.glue', function () {
    const tl = gsap.timeline();
    const $glue = $(this);
    $glue.removeClass("hovered")
    const $glueCircle = $('.glue__circle', this);
    if ($glueCircle.length === 0) {
        return
    }
    tl.to($glueCircle, {x: 0, y: 0, duration: 1, ease: 'linear'}, 0);
    const $glueCircleInner = $('.glue__circle-inner', this);
    if ($glueCircleInner.length === 0) {
        return
    }
    tl.to($glueCircleInner, {x: 0, y: 0, duration: 1, ease: 'linear'}, 0);
});
