import $ from 'jquery';
import Swiper, {
    Navigation, Pagination, Autoplay, Scrollbar, EffectFade, Parallax, EffectCoverflow,
} from 'swiper';
import 'selectric';

import "./main-scripts/glue_circle"
import "./main-scripts/starfield"
import "./main-scripts/ajaxLoader"
import "./vendors/modernizr"


console.log(Modernizr)
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}


let loadingProgress = 0;



function tick(){
    let increment = parseInt(getRandomArbitrary(6, 20));
    loadingProgress += increment;
    if(loadingProgress > 99){
        loadingProgress = 100;
        clearInterval(timerId);
    }
    console.log(loadingProgress)

}



import 'jquery-validation';

import { showPopBox, hidePopBox } from './main-scripts/pop-blocks.js';
import './main-scripts/alfa-form';
import './main-scripts/sk_scrollTo'


import smoothScrollbar from 'smooth-scrollbar';

Number.isNaN = Number.isNaN || function(value) {
    return typeof value === 'number' && isNaN(value);
};

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, EffectFade, Parallax, EffectCoverflow]);
const terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';
var $document = $(document);


// selectric (custom style for select) http://selectric.js.org/index.html
if ($('.js_selectCustom').length) {
    $('select.js_selectCustom').selectric({
        expandToItemText: true,
        disableOnMobile: false,
        onChange: function (element) {
            $(element).closest('.selectric-custom-select').addClass('selectric-changed').find('.selectric')
                .addClass('select-changed');
        },
        onOpen: function(element) {
            var elemScrolldiv = $(element).closest('.selectric-wrapper').find('.selectric-scroll');
            if (elemScrolldiv.length) {
                scrollerUpdate(elemScrolldiv);
            }
        },
    });
}

export function scrollerUpdate(scroller) {
    var scroll = smoothScrollbar.get(scroller[0]);
    scroll.update();
    var scrollSizes = scroll.getSize();
    if (scrollSizes.container.height < scrollSizes.content.height) {
        scroller.addClass('hasScroll');
    } else {
        scroller.removeClass('hasScroll');
    }
}
// styling for custom scroll  http://manos.malihu.gr/jquery-custom-content-scroller/
$('.selectric-scroll').each(function() {
    const fakeScroll = $(this);
    var scrollbarSlider = smoothScrollbar.init(fakeScroll[0], {
        damping: 0.07,
        continuousScrolling: false,
    });
});

$('._gCustomScroll').each(function() {
    var scrollElement = $(this);
    var itemScrollbar;
    var isContinious = false;
    if ($(this).data('continium')) {
        isContinious = true;
    }
    itemScrollbar = smoothScrollbar.init(scrollElement[0], {
        continuousScrolling: isContinious,
    });

    setTimeout(function () {
        scrollerUpdate(scrollElement);
    }, 300);
});

$('.no-device_xs ._gCustomScrollNOXS').each(function() {
    var scrollElement = $(this);
    var itemScrollbar;
    var isContinious = false;
    if ($(this).data('continium')) {
        isContinious = true;
    }
    itemScrollbar = smoothScrollbar.init(scrollElement[0], {
        continuousScrolling: isContinious,
    });

    setTimeout(function () {
        scrollerUpdate(scrollElement);
    }, 300);
});













